@import (reference) "style.less";

@validation-tooltip-color: red;
@layout-breakpoint-xs: 600px;
@top-bottom-margin: 5px;
@left-right-margin: 10px;

/* Shift down a little so that it is in the center of an input field. */
@top-offset: 3px;
@arrow-size: 8px;

@media only screen and (max-width: @layout-breakpoint-xs){
    span.tooltip-container{
        display:block;
    }
}

.arrow-left(@color, @height) {
    width: 0; 
    height: 0; 
    border-top: @height solid transparent;
    border-right: @height solid @color;
    border-bottom: @height solid transparent; 
}

.arrow-up(@color, @height) {
    width: 0; 
    height: 0; 
    border-left: @height solid transparent;
    border-bottom: @height solid @color;
    border-right: @height solid transparent;
}

.validation-tooltip {
    position: absolute;
    background-color: @validation-tooltip-color;
    border-radius: @default-border-radius;
    padding-top: @top-bottom-margin;
    padding-bottom: @top-bottom-margin;
    padding-left: @left-right-margin;
    padding-right: @left-right-margin;
    color: white;
    z-index: 10;
    white-space: nowrap;

    // CSS animations.

    // Stop the flickering - see https://github.com/angular/angular.js/issues/12969
    &.ng-enter-prepare {
        display: none
    }

    transition: .4s linear opacity;

    &.ng-enter {
        opacity: 0;

        &.ng-enter-active {
            opacity: 1;
        }
    }

    &.ng-leave {
        opacity: 1;

        &.ng-leave-active {
            opacity: 0;
        }
    }
}

.tooltip-container() {
    position: relative;
    top: @top-offset;
}

span.tooltip-container {
    .tooltip-container;

    /* Normal validation tooltip with an arrow on the left-hand-side and typically displayed on the right-hand-side of the input field */
    span {
        .validation-tooltip;

        &::before {
            content: '';
            position: absolute;
            left: -@arrow-size;
            top: 6px;
            .arrow-left(@color: @validation-tooltip-color, @height: @arrow-size);
        }
    }
}

div.tooltip-container-underneath {
    .tooltip-container;

    /* Normal validation tooltip with an arrow on the left-hand-side and typically displayed on the right-hand-side of the input field */
    span {
        .validation-tooltip;

        &::before {
            content: '';
            position: absolute;
            left: 9px;
            top: -@arrow-size;  
            .arrow-up(@color: @validation-tooltip-color, @height: @arrow-size);
        }
    }
}