@import (reference) "colours.less";
@import (reference) "page.less";
@import (reference) "fonts.less";

@action-icon-height: ceil(4 * @mm-to-pixels);
@background-colour: rgba(100, 100, 100, 0.6);

.image-darken(@url) {
    background: linear-gradient(@background-colour,@background-colour), @url;
}

.fill-warning-color {
    fill: @warning-color;
}

.fill-success-color {
    fill: @success-color;
}

.fill-neutral-color {
    fill: @neutral-color;
}

md-icon{
    min-height: 0;
    min-width: 0;
}

.svg-icon-size {
    vertical-align: top;
    height: 1em;
    width: ceil(1.5 * @mm-to-pixels);
}

.svg-icon-size-large {
    vertical-align: top;
    height: 1em;
    width: ceil(3 * @mm-to-pixels);
}

.fill-accent-color {
    fill: @accent-color;
}


.scale-icon-holder {
    cursor: pointer;

    .scale-icon {
        height: @action-icon-height;
    }

    .scale-icon-text-holder {
        padding-left: ceil(1 * @mm-to-pixels);

        span {
            font-size: @font-normal-size;
            color: @accent-color;
        }
    }
}

.fb-share {
    cursor: pointer;

    .fb-action-icon {
        color: @accent-color;
        font-size: @action-icon-height;
    }

    span {
        padding-left: ceil(1 * @mm-to-pixels);
        font-size: @font-normal-size;
        color: @accent-color;
    }
}
