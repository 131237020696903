/* https://google-developers.appspot.com/maps/documentation/javascript/places-autocomplete#style_autocomplete */

@import (reference) "colours.less";
@import (reference) "fonts.less";

@address-line-height: 2.5rem;

.pac-container {
    width: inherit !important;

    .pac-icon {
        display: none;
    }

    .pac-item {
        height: @address-line-height;
        line-height: @address-line-height;
        /* Remove the line separators between the search results. */
        border-top: 0;
    }

    .pac-item-selected {
        background-color: @body-background-color;
    }

    span {
        font-family: @font-family;
        font-size: @font-normal-size;
    }
}

