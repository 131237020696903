
@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes growUp {
    from {
        transform: scaleY(0);
    }

    to {
        transform: scaleY(1);
    }
}

.slide-down() {
    transform: translateY(-100%);
    animation: slideDown 1s;
    animation-delay: .3s;
    animation-fill-mode: forwards;
}

.grow-up(@animation-duration, @animation-delay, @child-node) {

    &.ng-enter {
        /* Required so that angular adds animation classes */
        animation-duration: @animation-duration;
    }

    &.ng-enter .@{child-node} {
        transform: scaleY(0);
    }

    &.ng-enter-active .@{child-node} {
        transform-origin: bottom;
        animation: growUp @animation-duration;
    }

    &.ng-enter-stagger {
        /* Applies a staggered effect to the animation */
        animation-delay: @animation-delay;
        animation-duration: 0s;
    }
}
