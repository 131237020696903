/* All body content should be wrapped like this in order to create a sticky footer. */
.body-container {
    display: flex; 
    min-height: 100vh; 
    flex-direction: column;
}

/* All body content should be wrapped like this in order to create a sticky footer. */
.body-content {
    flex: 1;
}