@import (reference) "colours.less";

md-slider.md-default-theme .md-thumb:after, md-slider .md-thumb:after {
    border-color: @accent-color;
    background-color: @accent-color;
}

md-slider.md-default-theme .md-track.md-track-fill, md-slider .md-track.md-track-fill {
    background-color: @accent-color;
}

/* Hide the focus area because it requires too much height */ 
md-slider.md-default-theme .md-focus-ring, md-slider .md-focus-ring {
    visibility: hidden;
}

/* Reduce height so that the text underneath is closer */
md-slider {
    margin-left: 0;
    margin-right: 0;
    height: 35px;
}

