@import (reference) "page.less";

.form-field {
    padding-top: 0;
    padding-bottom: 4px; /* Small gap so that any footnotes can be added. */
    align-items: center;
    display: flex;

    label {
        word-wrap: break-word;
    }

    input {
        width: 192px;
    }

    .required-indicator {
        color: @accent-color;
    }
}

.form-field-vertical-spacer {
    margin-bottom: 2 * @mm-to-pixels;
}