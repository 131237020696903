@import (reference) "page.less";
@import (reference) "colours.less";

@spinner-width: ceil(19 * @mm-to-pixels);
@spinner-height: ceil(10 * @mm-to-pixels);
@spinner-padding: ceil(3 * @mm-to-pixels);
@spinner-arrow-size: ceil(1.5 * @mm-to-pixels);

.spinner {
    width: @spinner-width;
    
    .image {
        fill: @accent-light-color;
        padding-left: @spinner-padding;
    }

    .up-down-arrow-seperator {
        height: ceil(1.5 * @mm-to-pixels);
    }

    .up-down-arrow-container {
        cursor: pointer; 
        height: 100%; 
        outline: none;      /* Stop the focus highlight being displayed in Chrome */

        &:active {
            md-icon {
                fill: @accent-color;
            }
        }

        md-icon {
            fill: black;
            height: @spinner-arrow-size; 
            width: @spinner-arrow-size; 
            padding: 0; 
            margin: 0;
        }
    }

    .value-container {
        vertical-align: middle; 
        width: 1.5em; 
        text-align: center;
        user-select: none;
    }

    .name-container {
        user-select: none;
    }
}
