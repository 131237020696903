@import (reference) "colours.less";
@import (reference) "button.less";

.k-state-hover {
    box-shadow: none;
    transition: none;
}

.k-datepicker {
    background-color: transparent;
}

.k-picker-wrap.k-state-default {
    background-color: transparent;
    border: 1px solid @tertiary-text-color !important;
    border-radius: @button-border-radius !important;

    span.k-select {
        line-height: 30px;
        min-height: 30px;
        border: none;
    }
}

.k-numeric-wrap:before, .k-picker-wrap:before {
    height: auto;
}

.k-picker-wrap.k-state-focused {
    box-shadow: none !important;
    transition: none;
    background-color: none;
}

.k-calendar-container {
    font-size: 10px !important;

    .k-widget.k-calendar {
        min-width: 240px;
    }

    .k-content {
        font-weight: bold;
    }

    table.k-content thead tr th {
        color: @calendar-day-of-week-color;
        font-weight: bold;
        text-align: center;
    }

    .k-state-hover {
        background-color: @calendar-highlight-color;
    }

    table.k-content td.k-state-selected {
        background-color: transparent;
        box-shadow: none;

        .k-link {
            color: @accent-light-color;
            background-color: initial;
            border-radius: initial;
            border-bottom: @accent-light-color solid @calendar-accent-height;
        }
    }
}

.k-datepicker .k-input {
    background-color: transparent;
    height: ceil(6.2 * @mm-to-pixels) !important;
    line-height: ceil(6.2 * @mm-to-pixels) !important;
}

.k-picker-wrap .k-input {
    padding: 0;
}