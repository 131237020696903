@import (reference) "colours.less";
@import (reference) "fonts.less";
@import (reference) "checkbox.less";
@import (reference) "page.less";
@import (reference) "style.less";

@customer-profile-img-height: 31 * @mm-to-pixels;
@customer-profile-img-width: @customer-profile-img-height;

.customer-profile-image {
    height: 31 * @mm-to-pixels;
    width: 31 * @mm-to-pixels;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #C4CCDF;
    object-fit: cover;
}

.customer-profile-container {
    height: 31 * @mm-to-pixels;
    width: 31 * @mm-to-pixels;
    position: relative;
    top: 0;
    left: 0;
}

img.customer-profile-image {
    display: none;
}

/* Image load success so display it */
img[src].customer-profile-image {
    display: initial;
}

.profile-details-container {
    margin-top: 5 * @mm-to-pixels;
}

.customer-profile-img {
    height: @customer-profile-img-height;
    width: @customer-profile-img-width;
    background-size: cover;
    border-radius: 50%;
    background-position: center;
}

.button-holder {
    margin-top: 4 * @mm-to-pixels;
    width: @customer-profile-img-height;

    .choose-img-button {
        margin: 0;
    }
}

.personal-info-container {
    .pencil-icon {
        padding-left: 1 * @mm-to-pixels;
        padding-right: 1 * @mm-to-pixels;
        margin-left: 1 * @mm-to-pixels;
        cursor: pointer;
    }

    .info-item {
        padding-top: 7px;
        padding-bottom: 7px;
        display: flex;
        align-items: center;

        .profile-icons {
            vertical-align: top;
            height: 1em;
        }

        .name-input input {
            width: 35%;
            min-width: unset !important;
        }

        input {
            width: 97%;
            min-width: 120px;
            color: @accent-color;
        }

        .icon {
            //width: ceil(3 * @mm-to-pixels);
            padding-right: ceil(3 * @mm-to-pixels);
            margin: auto;
            text-align: center;

            i {
                color: @accent-color;
                font-size: ceil(3 * @mm-to-pixels);
            }
        }

        .property {
            width: 19 * @mm-to-pixels;
        }

        .value {
            width: 40 * @mm-to-pixels;
        }
    }

    .k-widget.k-datepicker.k-header {
        width: 97%;
    }
}

.reward-card-container {

    .scan-it-text {
        margin-left: 5 * @mm-to-pixels;
    }
}

.health-checkbox-container > div > div {
    height: ceil(6 * @mm-to-pixels);

    md-checkbox {
        margin-top: floor((6 * @mm-to-pixels - @checkbox-width-height) / 2);
    }
}

#barcode {
    //border: 1px dashed @tertiary-text-color;
    display: inline-block !important;
    padding-top: 2 * @mm-to-pixels;
    padding-bottom: 2 * @mm-to-pixels;
    height: 15 * @mm-to-pixels;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.add-charity-button {
    width: 100%;
}

.community-benefits {

    .divider {
        height: 1px;
        background-color: @divider-color;
        margin-top: -15px;
        margin-bottom: 15px;
    }
}

.charity-name {
    margin-right: 5px;
}

div.tooltip-container-underneath.community-benefits-total-message {
    left: -6px;
    top: 9px;
}

.community-benefits-edit-button-row {
    margin-top: 5px;

    .md-button:first-of-type {
        margin-right: 3px;
    }
}

.home-store-select {
    max-width: 150px;
    margin-left: 40px;
}

.contribution-rate-input {
    width: 47px;
    text-align: right;
    margin-right: 2px;
}

.contribution-rate-total-row-label {
    text-align: right;
    margin-right: 13px;
}

.contribution-rate-total-row-percentage {
    margin-right: 21px;
}

.cardholder-edit {
    height: 15px;
    fill: white;
}

.link-card-segment {
    padding: 12px 20px 8px;
    margin: 0 -24px;
    background-color: rgba(0,0,0,0.2);

    form > div, > div {
        margin-top: 6px;
    }
}

.cancel-card-segment {
    .link-card-segment;
}

.cancel-card-button-segment {
    .link-card-segment;
    margin-bottom: 8px;
    padding-top: 1px;
}

md-icon.set-primary-cardholder-cog {
    .clickable;
    margin-top: -5px;
    fill: @accent-color;
}

.set-primary-cardholder() {
    margin-right: 10px;
}

.set-primary-cardholder-container {
    margin-top: 6px;
    .set-primary-cardholder;
}

.set-primary-cardholder-input-container {
    min-width: 200px;
    .set-primary-cardholder;

    select {
        width: 100%;
    }
}
