@import (reference) "page.less";
@import (reference) "colours.less";
@import (reference) "fonts.less";

@list-header: #e2e2e2;


#shopping-basket-holder {
    font-size: @font-small-size;
    color: @secondary-text-color;

    #shopping-basket-grid {
        height: 458px;
    }

    .k-grid-content.k-auto-scrollable {
        height: 420px;
    }

    .k-grid-content {
        overflow-y: hidden;
    }

    .k-grid-header {
        padding-right: 0 !important;
    }

    .k-grid .k-alt {
        background-color: #f5f7f8; /* specify the alternate background-color */
    }

    .k-grid-header th.k-header {
        text-align: center;
        padding-left: 1.286em;
        padding-right: 1.286em;
    }

    .center-align {
        width: 100%;
        text-align: center;
    }
    //reove hover color
    .k-grid tr:hover {
        background: transparent;
    }
    //remove alternative hover color
    .k-grid tr.k-alt:hover {
        background: #f5f7f8;
    }

    .k-grid td {
        padding-top: 10px;
        padding-bottom: 10px;
        border-width: 0;
    }

    .k-header {
        color: black;
        font-weight: bold;
        border-width: 0;
    }

    md-icon {
        height: ceil(2 * @mm-to-pixels);
        user-select: none;
    }

    md-icon:focus {
        outline: 0;
        box-shadow: none;
    }

    .add-to-list-active {
        fill: @accent-color;
        cursor: pointer;
    }

    .discount-span {
        background-color: @accent-color;
        color: white;
        border-radius: 10px;
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    //scroll bar styling
    .mCSB_dragger_bar {
        background-color: @accent-color !important;
    }

    .mCSB_inside > .mCSB_container {
        margin-right: 0 !important;
    }

    .mCSB_scrollTools .mCSB_draggerContainer {
        left: 10px;
    }

    .mCSB_draggerRail {
        background-color: @accent-light-color;
    }
    //scroll bar styling ends here

    input{
        height: auto;
    }
}

.shopping-list-header {
    height: ceil(6 * @mm-to-pixels);
    line-height: ceil(6 * @mm-to-pixels);
    background-color: @common-theme-color;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    color: @secondary-text-color;
    font-size: @font-small-size;
    margin-top: ceil(2 * @mm-to-pixels);
}

.expandar-holder {
    text-align: center;
    margin-top: 4 * @mm-to-pixels;

    span {
        cursor: pointer;
        color: @accent-color;
        font-weight: bold;
    }

    md-icon {
        height: ceil(2 * @mm-to-pixels);
        fill: @common-theme-color;
    }
}
