@import (reference) "colours.less";
@import (reference) "fonts.less";
@import (reference) "page.less";
@import (reference) "images.less";

@banner-text-color: white;
@customer-info-text-color: white;
@points-font-size: 3 * @mm-to-pixels;
@customer-info-height: 20 * @mm-to-pixels;
@customer-info-width: @customer-info-height;

@banner-logo-url: null;
@dashboard-logo-url: null;
@profile-logo-url: null;
@shopping-logo-url: null;

.dashboard-banner {
     /*.image-darken(url('/content/images/banner-vegetables.jpg'));*/
     .image-darken(url(@dashboard-logo-url));
}

.customer-profile-banner {
     /*.image-darken(url('/content/images/banner-strawberries.jpg'));*/
     .image-darken(url(@profile-logo-url));
}

.shopping-banner {
     /*.image-darken(url('/content/images/banner-blueberries.jpg'));*/
     .image-darken(url(@shopping-logo-url));
}

.default-banner {
    /*.image-darken(url('/content/images/banner-landing.jpg'));*/
    .image-darken(url(@banner-logo-url));
      
}
.dashboard-banner() when not (isstring(@dashboard-logo-url)) {
    display: none;
}

.customer-profile-banner() when not (isstring(@profile-logo-url)) {
    display: none;
}

.shopping-banner() when not (isstring(@shopping-logo-url)) {
    display: none;
}

.default-banner() when not (isstring(@banner-logo-url)) {
    display: none;
}

.circular-img {
    height: @customer-info-height;
    width: @customer-info-width;
    border-radius: 50%;
    border: 1 * @mm-to-pixels solid white;
}

.customer-img-container {
    height: @customer-info-height;
    width: @customer-info-width;
    position: relative;
    top: 0;
    left: 0;
}

.customer-img {
    .circular-img;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #C4CCDF;
    object-fit:cover;
}

img.customer-img {
    display:none;
}

/* Image load success so display it */
img[src].customer-img {
    display: initial;
}

.company-img {
    height: @customer-info-height;
    border-radius: 5px;
    display: block;
    border: 1 * @mm-to-pixels solid white;       
    background-color: white; 
}

.customer-info-container {
    padding-bottom: 7 * @mm-to-pixels;
}

.customer-info {
    color: @customer-info-text-color;
    padding-left: 5 * @mm-to-pixels;
    font-family: Lato;


    .info-date-line {
        text-transform: uppercase;
        font-size: 2 * @mm-to-pixels;
        font-weight: bold;
    }

    .info-customer-name-line {
        font-size: 8 * @mm-to-pixels;
        vertical-align: middle;
        line-height: 15 * @mm-to-pixels;
        font-weight: bold;
    }

    .points-background {
        background-color: black;
        border-radius: 2 * (1 * @mm-to-pixels + @points-font-size); //2 * paddingTop
        padding: 1 * @mm-to-pixels 3 * @mm-to-pixels;
        font-size: @points-font-size;
        font-family: Bariol;
    }

    .points-text {
        padding: 1 * @mm-to-pixels;
        font-size: @points-font-size;
        font-family: Bariol;
    }
}


.banner-container {
    padding-bottom: ceil(9 * @mm-to-pixels);
    color: @banner-text-color;

    .title {
        font-size: 50px;
        font-weight: bold;
    }

    .subtitle {
        padding-top: 7px;
    }
}
