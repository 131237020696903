@import (reference) "colours.less";

@mm-to-pixels: 4.8px; /* 4.8 = 1000 / 205mm (based on A3 printout). Used when measuring the sizes from the design document and translating them to pixels. */

@page-image-banner-height: 55 * @mm-to-pixels;
@page-panes-margin: 2.5 * @mm-to-pixels; /* Spacing between widgets */
@page-max-width: 205 * @mm-to-pixels;
@right-hand-pane-width: 65 * @mm-to-pixels;
@footer-logo-url: null;
@layout-breakpoint-xs: 600px;
@layout-breakpoint-sm: 960px;
@layout-breakpoint-md: 1280px;
@layout-breakpoint-lg: 1920px;

.content-container {
    justify-content: center;
    display: flex;
}

/* Centered content for the page */
.content {
    width: @page-max-width;
    margin-left: @page-panes-margin;
    margin-right: @page-panes-margin;
}

.page-image-banner-height {
    height: @page-image-banner-height;
}

@media only screen and (max-width: @layout-breakpoint-xs){
    .page-image-banner-height {
        display:none;
    }
}

/* Vertical left pane - assumed to be adjunct to .page-right-pane */
.page-left-pane {
    margin-top: @page-panes-margin;
}

/* Vertical left pane - assumed to be adjunct to .page-right-pane */
.page-full-width-pane {
    margin-top: @page-panes-margin;
}

/* Vertical right pane - assumed to be adjunct to .page-left-pane */
.page-right-pane {
    margin-top: @page-panes-margin;
    margin-left: @page-panes-margin;
    min-width: @right-hand-pane-width;
}

@media (min-width: @layout-breakpoint-sm) {
    #page-right-pane {
        width: @right-hand-pane-width;
    }
}

@media only screen and (max-width: @layout-breakpoint-xs){
    #login-container{
        flex-direction:column;
    }
    #register-container{
        flex-direction:column;
    }
    #password-container{
        flex-direction:column;
    }

    .contactus-container .group-box{
        max-width:350px;
        margin:0 auto;
    }
    .content {
        /*
        width: 500px;*/
        margin-left: 0;
        margin-right: 0;
    }

    #register-section{
        max-width:350px;
        flex: 1 1 auto;
        margin:0 auto;
    }
    #login-section{
        max-width:350px;
        flex: 1 1 auto;
        margin:0 auto;
    }
    #newAccount-section{
        max-width:350px;
        flex: 1 1 auto;
        margin:0 auto;
    }
    #link-section{
        max-width:350px;
        flex: 1 1 auto;
        margin:0 auto;
    }
    #reset-section{
        max-width:350px;
        flex: 1 1 auto;
        margin:0 auto;
    }
    #reset-section .resetQuestion{
        width:192px;
    }
  
    #resend-section{
        max-width:350px;
        flex: 1 1 auto;
        margin:0 auto;
    }
    #resend-section .email-button-holder{
        width:132px !important;
    }
    .contact-label{
        width:300px !important;
        margin-left:40px;
    }
    .contact-p{
        text-align:center;
    }
    .contact-input{
        margin: 0 auto;
        float: none !important;
    }
    .contact-buttons{
        margin:0 auto !important;
        display:block;
    }
    .contact-input span.tooltip-container span{
        display:block;
    }
    .contact-input-message{
        top:25px !important;
    }
    .contact-input-message span{
        display:block;
    }
    .widget-container-light{
        padding:0px !important;
    }
    .linkcard-input span{
        display:block;
    }
    .newcardholder{
        margin-bottom:10px;
    }
    .newcardholder input{
        margin-bottom:10px;
    }
    .setting-password .existPdField .form-field {
        flex-direction: column !important;
    }
    .setting-password .newPdField .form-field {
        flex-direction: column !important;
    }
    .setting-password .confirmPdField .form-field {
        flex-direction: column !important;
    }
    .setting-password .form-field .button-holder{
        width: 100px;
    }
    .setting-question .questionField .form-field {
        flex-direction: column !important;
    }
    .setting-question .questionField .form-field .label-field{
        width:250px !important;
    }
    .setting-question .questionField .form-field select{
        width:250px !important;
    }
    .setting-question .answerField .form-field {
        flex-direction: column !important;
    }
    .setting-question .answerField .form-field  .label-field{
        width:250px !important;
    }
    .setting-question .form-field .button-holder{
        width: 120px;
    }
    .setting-pin{
        max-width:350px;
        margin:0 auto;
    }
    .setting-question{
        max-width:350px;
        margin:0 auto;
    }
    .setting-password{
        max-width:350px;
        margin:0 auto;
    }
    .setting-pin .cardNumberField .form-field {
        flex-direction: column !important;
    }
    .setting-pin .cardNumberField .form-field .label-field {
        width: 255px !important;
    }
    .setting-pin .pinField .form-field {
        flex-direction: column !important;
    }
    .setting-pin .newPinField .form-field {
        flex-direction: column !important;
    }
    .setting-pin .confirmPinField .form-field {
        flex-direction: column !important;
    }
    .setting-pin .pinField  md-checkbox .md-container{
        left:auto !important;
    }
    .setting-pin .pinField  md-checkbox{
        display:block;
        margin-left:auto;
        margin-right:auto;
        flex-direction:column;
        width:220px;
    }
    .setting-pin form-field .button-holder{
        width: 120px;
    }
    .registration-name .form-field{
        flex-direction: column !important;
    }
    .registration-email .form-field{
        flex-direction: column !important;
    }
    .registration-mobile .form-field{
        flex-direction: column !important;
    }
    .registration-phone .form-field{
        flex-direction: column !important;
    }
    .registration-address .form-field{
        flex-direction: column !important;
    }
    .registration-password .form-field{
        flex-direction: column !important;
    }
    .registration-confirmPassword .form-field{
        flex-direction: column !important;
    }
    .registration-question .form-field{
        flex-direction: column !important;
    }
    .registration-answer .form-field{
        flex-direction: column !important;
    }
    .registration-pin .form-field{
        flex-direction: column !important;
    }
    .registration-confirmPin .form-field{
        flex-direction: column !important;
    }
    .registration-charity .form-field{
        flex-direction: column !important;
    }


    //For form 1 
    .formOne-date .form-field{
        flex-direction: column !important;
    }
    .formOne-email .form-field{
        flex-direction: column !important;
    }
    .formOne-mobile .form-field{
        flex-direction: column !important;
    }
    .formOne-phone .form-field{
        flex-direction: column !important;
    }
    .formOne-fax .form-field{
        flex-direction: column !important;
    }
    .formOne-tradingAs .form-field{
        flex-direction: column !important;
    }
    .formOne-abn .form-field{
        flex-direction: column !important;
    }
    .formOne-owner .form-field{
        flex-direction: column !important;
    }
    .formOne-manager .form-field{
        flex-direction: column !important;
    }
    .formOne-newBusiness .form-field{
        flex-direction: column !important;
    }
    .formOne-address .form-field{
        flex-direction: column !important;
    }
    .formOne-postcode .form-field{
        flex-direction: column !important;
    }
    .formOne-state .form-field{
        flex-direction: column !important;
    }


    .formOne-date .control-field .k-widget{
        width:250px !important;
    }
    .formOne-state .form-field input
    {
        width:250px !important;
    }
    .formOne-email .form-field input
    {
        width:250px !important;
    }
    .formOne-mobile .form-field input
    {
        width:250px !important;
    }
    .formOne-phone .form-field input
    {
        width:250px !important;
    }
    .formOne-fax .form-field input
    {
        width:250px !important;
    }
    .formOne-tradingAs .form-field input
    {
        width:250px !important;
    }
    .formOne-abn .form-field input
    {
        width:250px !important;
    }
    .formOne-owner .form-field input
    {
        width:250px !important;
    }
    .formOne-manager .form-field input
    {
        width:250px !important;
    }
    .formOne-newBusiness .form-field input
    {
        width:250px !important;
    }
    .formOne-address .form-field input
    {
        width:250px !important;
    }
    .formOne-postcode .control-field
    {
        width:250px !important;
    }
    .formOne-postcode .control-field input
    {
        width:80px !important;
    }
    .formOne-state .form-field .control-field
    {
        width:250px !important;
    }
    .formOne-state .form-field .control-field select
    {
        width:80px !important;
    }
  
    // END
    .titleField{
        width: 100px;
        display: block;
    }
    .firstnameField input{
        width: 250px !important;
        display: block;
    }
    .mobileField input{
        width: 250px !important;
    }
    .phoneField input{
        width: 250px !important;
    }
    .postcodeField input{
        width: 250px !important;
    }
    .passwordField input{
        width: 250px !important;
    }
    .confirmPasswordField input{
        width: 250px !important;
    }
    .promoCodeField input{
        width: 250px !important;
    }

    .titleField {
        margin-bottom:20px;
    }
    .answerField input{
        width: 250px !important;
    }
    .lastnameField input{
        width: 250px !important;
        display: block;   
    }
    .registration-email input{
        min-width:250px !important;
    }
    .registration-address .streetNoField input{
        min-width:100px !important;
        display: block;
        margin-bottom:20px;
    }
    .registration-address .streetNameField input{
        min-width:250px !important;
        display: block;
        margin-bottom:20px;
    }
    .registration-address .suburbField input{
        min-width:250px !important;
        display: block;
        margin-bottom:20px;
    }
    .registration-address .postcodeField input{
        min-width:100px !important;
        display: block;
        margin-bottom:20px;
    }
    .registration-pin input{
        min-width:250px !important;
    }
    .registration-confirmPin input{
        min-width:250px !important;
    }
    .registration-name .label-field{
        width:250px !important;
        margin-top: 20px;
    }
    .registration-email .label-field{
        width:250px !important;
        margin-top:20px;
    }
    .registration-mobile .label-field{
        width:250px !important;
    }
    .registration-phone .label-field{
        width:250px !important;
    }
    .registration-address .label-field{
        width:250px !important;
        margin-top:20px;
    }
    .registration-password .label-field{
        width:250px !important;
    }
    .registration-confirmPassword .label-field{
        width:250px !important;
    }
    .registration-question .label-field{
        width:250px !important;
        margin-top:20px;
    }
    .registration-answer .label-field{
        width:250px !important;
    }  
    .registration-address .control-field{
        margin-left:15px;
    }
    .registration-pin .label-field{
        width:250px !important;
    }
    .registration-confirmPin .label-field{
        width:250px !important;
    }
    .registration-charity .label-field{
        width:250px !important;
    }
    .registration-charity .charityField{
        max-width: 250px !important;
    }
    .registration-question .questionField{
        max-width: 250px !important;
    }
    .registration-banner .info-banner{
        min-height:50px;
        line-height:1.5em;
        max-width:300px;
        margin:0 auto;
    }

    //For form 1 
    .formone-banner .info-banner{
        min-height:50px;
        line-height:1.5em;
        max-width:330px;
        margin:20px auto;
    }


    .formOne-date .label-field{
        width:250px !important;
    }
    .formOne-email .label-field{
        width:250px !important;
    }
    .formOne-mobile .label-field{
        width:250px !important;
    }
    .formOne-phone .label-field{
        width:250px !important;
    }
    .formOne-fax .label-field{
        width:250px !important;
    }
    .formOne-tradingAs .label-field{
        width:250px !important;
    }
    .formOne-abn .label-field{
        width:250px !important;
    }
    .formOne-owner .label-field{
        width:250px !important;
    }
    .formOne-manager .label-field{
        width:250px !important;
    }
    .formOne-newBusiness .label-field{
        width:250px !important;
    }
    .formOne-address .label-field{
        width:250px !important;
    }
    .formOne-postcode .label-field{
        width:250px !important;
    }
    .formOne-state .label-field{
        width:250px !important;
    }
    // END

    //END
    .linkcardRegistration form{
        max-width:350px;
        margin:0 auto;
    }
    .shoppingSummary .widget-container-light{
        max-width:350px;
        margin:0 auto !important;
    }
    .shoppingBasket .widget-container-dark{
        max-width:350px;
        margin:0 auto !important;
    }
    .shoppingBasket{
        margin-left:0 !important;
    }
    .profileDetail form{
        max-width:350px;
        margin:0 auto;
    }
    .profileCharity .widget-container-dark{
        max-width:350px;
        margin:0 auto !important;
    }
    .profileCharity .widget-container-light{
        max-width:350px;
        margin:0 auto !important;
    }
    .profileCharity{
        margin-left:0 !important;
    }

    .dashboardSummary .widget-container-light{
        max-width:350px;
        margin:0 auto;
    }
    .dashboardShopping .widget-container-dark{
        max-width:350px;
        margin:0 auto;
    }
    .dashboardShopping{
        margin-left:0px;
    }
    .profileDetail .primaryCardholder{
        flex-direction:column !important;
    }
     .profileDetail .primaryCardholder .buttons{
         flex-direction:row !important;
     }
     .savebtn{
         width:100px;
         margin-left:100px;
     }
     .page-footer .footer-text{
         display:none;
     }
     .mereo-footer-url a{
         display:none;
     }
     .my-profile-wizard-step-height{ 
         height:190px !important;
         flex-direction: row;
     }
     #my-profile-wizard{
         max-height:500px !important;
         display:block;
     }
     .wizard-diets{
         flex-direction:row !important;
     }
     .wizard-diets .dietSection{
         //flex-direction: row !important;
     }
     .wizard-diets .dietSection md-checkbox{
         width:120px !important;
     }
     .wizard-diets .wizard-input-container{
         padding-left:0px !important;
         flex-direction:column !important;
     }
     .wizard-diets-container{
         height: 180px !important;
     }
     .my-profile-step-container{
         margin-top:0px !important;
     }
     .wizard-step-next{
         transform: translateX(-100%) !important;
     }
     .personal-info-container .info-item .icon{
         width:30px !important;
     }
     .virturalcard-banner .info-banner{
        height: 75px !important;
        line-height:25px !important;
    }
    .body-content{
        background-color:#fff;
    }

    .dashboard-barcode #barcode{
        height:120px;
        width:200px;
    }

    .virtual-option{
        margin:0 auto;
        max-width: 350px;
    }

     .virtual-option button{
        width:250px;
        margin: 10px auto !important; 
        display: block;   
     }

     .virtual-registration{
         max-width:350px;
         margin:0 auto !important;
     }
     .virtual-registration-banner .info-banner{
        min-height: 50px;
        line-height: 1.5em;
        max-width: 350px;
        margin: 0 auto;
     }
     .virtual-registration-email input{
        min-width: 250px !important;
     }
     .virtual-registration-person .form-field{
        flex-direction: column !important;
     } 
     .virtual-registration-person .label-field{
        width: 250px !important;
        margin-top: 0px;
     } 
     .virtual-registration-store .form-field{
         flex-direction: column !important;
         min-width: 250px;
     } 
     .virtual-registration-store .label-field{
        width: 250px !important;
        margin-top:20px;
     } 
     .virtual-registration-email .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-email .label-field{
        width: 250px !important;
        margin-top:0px;
     } 
     .virtual-registration-mobile .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-mobile .label-field{
        width: 250px !important;
     } 
     .virtual-registration-phone .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-phone .label-field{
        width: 250px !important;
     } 
     .virtual-registration-postcode .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-postcode .label-field{
        width: 250px !important;
     } 
     .virtual-registration-password .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-password .label-field{
        width: 250px !important;
        margin-top:0px;
     } 
     .virtual-registration-title{
         margin-bottom:0px; 
         margin-top:20px;
     }

     .virtual-registration-confirmPassword .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-promotion .label-field{
        width: 250px !important;
     }     
     .virtual-registration-promotion .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-confirmPassword .label-field{
        width: 250px !important;
     }    
     .virtual-registration-question .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-question .label-field{
        width: 250px !important;
        margin-top:20px;
     }
     .virtual-registration-question .questionField {
        max-width: 250px !important;
     }
     .virtual-registration-answer .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-answer .label-field{
        width: 250px !important;
     }  
     .virtual-registration-pin .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-pin .label-field{
        width: 250px !important;
     }
     .virtual-registration-pin input{
        width: 250px !important;
     } 
     .virtual-registration-confirmPin .form-field{
         flex-direction: column !important;
     } 
     .virtual-registration-confirmPin .label-field{
        width: 250px !important;
     } 
     .virtual-registration-confirmPin input{
        width: 250px !important;
     } 
     .virtual-registration-emailSubscription, .virtual-registration-smsSubscription{
         width:350px;
         display:block;
         margin:0 auto;
     }
     .recaptchaCheck{
         width:300px;
         display:block;
         margin:0 auto;
     }
     .Virtual-registration-button{
         width:250px;
         display:block;
         margin:0 auto !important;
     }
     .registration-complete{
        max-width: 350px;
        margin: 0 auto;
     }
     .registration-complete-button{
         display:block;
         width:250px;
         margin:0 auto !important;
     }
     .termsandcondition
     {
        max-width: 350px;
        margin: 0 auto;
     }
     .licensingagreement{
        max-width: 350px;
        margin: 0 auto;
     }
     .reset-password{
        max-width: 350px;
        margin: 0 auto;
     }
     .resend-password{
        max-width: 350px;
        margin: 0 auto;
     }
     .registerPanel{
        max-width: 350px;
        margin: 0 auto;
     }
     .registration-detail{
        max-width: 350px;
        margin: 0 auto;
     }
     .settingPanel{
        max-width: 350px;
        margin: 0 auto;
     }
     .primaryCardholderPanel{
        max-width: 350px;
        margin: 0 auto;
     }
     .virtualInitialPanel{
        max-width: 350px;
        margin: 0 auto;
     }
     .virtualCompletePanel{
        max-width: 350px;
        margin: 0 auto;
     }
     .cardShowPanel{
        max-width: 350px;
        margin: 0 auto;
     }
     .profile-gender-edit .group-buttons{
         flex-direction:column !important;
     }
     .resend-password-form .label-field{
         width:0px !important;
     }
}

@media only screen and (min-width: @layout-breakpoint-md){
    #login-container{
        flex-direction:row
    }
    #register-container{
        flex-direction:row
    }
    #password-container{
        flex-direction:row
    }
    .content {
        width: 1000px;
        margin-left: @page-panes-margin;
        margin-right: @page-panes-margin;
    }
    #register-section{
        max-width:460px;    
        flex: 1 1 auto;
        float:right;
    }
    #login-section{
        max-width:460px;    
        flex: 1 1 auto;
        float:left;
    }
    #newAccount-section{
        max-width:460px;    
        flex: 1 1 auto;
        float:left;
    }
    #link-section{
        max-width:460px;    
        flex: 1 1 auto;
        float:right;
    }
    #reset-section{
        max-width:460px;    
        flex: 1 1 auto;
        float:right;
    }
    #resend-section{
        max-width:460px;    
        flex: 1 1 auto;
        float:left;
    }
}

.linkcard-input{
    width:192px;
}

#login-container{
    min-height:350px;
}

#register-container{
    min-height:380px;
}

#password-container{
    min-height:350px;
}


.page-footer {
    margin-top: ceil(5 * @mm-to-pixels);
    background-color: @primary-color;
    color: white;
    text-align: center;
    min-height:50px;

    .mereo-footer-title {
        font-size: 3.5 * @mm-to-pixels;
        font-weight: bold;
        text-align: center;
    }
}

.footer-text{
    display:block;
    float:right;
    padding-top:15px;
    margin-bottom: 0px !important;
    font-size: 13px;
}

.footer-text p{
    margin:15px auto 0px auto !important;
}

.mereo-footer-url{
    display:block;
    float:left;
    padding-top:15px;
    margin-bottom: 0px !important;
    font-size: 13px;
    text-decoration :none;
    color:white;
    font-weight:bold;
    margin-left:20px;
}

.mereo-footer-url a{
    text-decoration :none;
    color:white;
}

.mereo-footer-url a:active{
    text-decoration :none;
    color:white;
}

.mereo-footer-url a:hover{
    text-decoration :none;
    color:white;
}

.mereo-footer-url a:after{
    text-decoration :none;
    color:white;
}


/* There will be a two dots at the top and bottom of the line unless the height is an odd number. */
.vertical-divider {
    background: url('/content/images/divider-circle.svg') repeat;
    width: 2px;
    /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
}

.page-vertical-divider {
    .vertical-divider;
    margin-left: 5 * @mm-to-pixels;
    margin-right: 5 * @mm-to-pixels;
}

.horizontal-divider {
    background: url('/content/images/divider-circle.svg') repeat;
    height: 2px;
    /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
}

.page-horizontal-divider {
    .horizontal-divider;
    margin-top: 5 * @mm-to-pixels;
    margin-bottom: 5 * @mm-to-pixels;
}

.cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    position: fixed;
    background-color: transparent;
}

.mereo-footer-logo {
    .mereo-footer-logo;
    /* Image container */
    div {
        background-repeat: no-repeat;
        background-size: contain;
        height: 8.5 * @mm-to-pixels;
        width:200px;
        border-style: none;
        background-image: url(@footer-logo-url);
        margin:12px auto 0px auto;
    }
}

.mereo-footer-logo() when not (isstring(@footer-logo-url)) {
    display: none;
}

.contact-label{
    width:120px !important;
    display:block;
    float:left;
}

.contact-input{
    display: block;
    width: 260px !important;
    float: left;
}

.contact-input input{
    display: block;
    width: 260px !important;
    float: left;
}

.virturalcard-banner {
    margin-bottom: 40px;
    display: block;
}

.virturalcard-email .emailField input{
    width:250px !important;
    min-width:220px !important;
}
.virturalcard-widget{
    background-color: transparent !important;
}

#virtural_button button{
    margin: 0 auto;
    width: 100px;
    display: block;
    margin-top: 30px;
}

