@import (reference) "page.less";

/* This is the smallest size that is legible for the the Bariol font. */
@font-small-size: ceil(2.8 * @mm-to-pixels);
@font-normal-size: ceil(3 * @mm-to-pixels);
@font-medium-size: ceil(3.2 * @mm-to-pixels);
@font-medium-large-size: ceil(3.8 * @mm-to-pixels);
@font-large-size: ceil(6 * @mm-to-pixels);
@font-extra-large-size: ceil(10 * @mm-to-pixels);

@OTF: 'OTF';
@WOFF: 'WOFF';

@regular-font-src: '/fonts/Bariol_Regular';
@bold-font-src: '/fonts/Bariol_Bold';
@thin-font-src: '/fonts/Bariol_Thin';
@light-font-src: '/fonts/Bariol_Light';
@regular-italic-font-src: '/fonts/Bariol_Regular_Italic';
@bold-italic-font-src: '/fonts/Bariol_Bold_Italic';
@thin-italic-font-src: '/fonts/Bariol_Thin_Italic';
@light-italic-font-src: '/fonts/Bariol_Light_Italic';

@font-family: 'Bariol';

.set-fonts(@WOFF);

.set-fonts(@format) when (@format = @OTF){
    @format: 'opentype';
    @ext: 'otf';
}

.set-fonts(@format) when (@format = @WOFF){
    @format: 'woff';
    @ext: 'woff';
}

@font-face {
    font-family: @font-family;
    font-weight: normal;
    font-style: normal;
    src: url("@{regular-font-src}.@{ext}") format(@format);
}

@font-face {
    font-family: @font-family;
    font-weight: bold;
    font-style: normal;
    src: url("@{bold-font-src}.@{ext}") format(@format);
}

@font-face {
    font-family: @font-family;
    font-weight:lighter;
    font-style: normal;
    src: url("@{light-font-src}.@{ext}") format(@format);
}

@font-face {
    font-family: @font-family;
    font-weight: 100;
    font-style: normal;
    src: url("@{thin-font-src}.@{ext}") format(@format);
}



@font-face {
    font-family: @font-family;
    font-weight: normal;
    font-style: italic;
    src: url("@{regular-italic-font-src}.@{ext}") format(@format);
}

@font-face {
    font-family: @font-family;
    font-weight: bold;
    font-style: italic;
    src: url("@{bold-italic-font-src}.@{ext}") format(@format);
}

@font-face {
    font-family: @font-family;
    font-weight:lighter;
    font-style: italic;
    src: url("@{light-italic-font-src}.@{ext}") format(@format);
}

@font-face {
    font-family: @font-family;
    font-weight: 100;
    font-style: italic;
    src: url("@{thin-italic-font-src}.@{ext}") format(@format);
}

.font-small {
    font-size: @font-small-size;
}

.font-normal {
    font-size: @font-normal-size;
}

.font-medium {
    font-weight: bold;
    font-size: @font-medium-size;       
}

.font-medium-large {
    font-weight: bold;
    font-size: @font-medium-large-size;
}

.font-large {
    font-size: @font-large-size;
}

.font-extra-large {
    font-size: @font-extra-large-size;       
}


//customer profile fonts
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Lato-Regular-Latin-ext.woff') format('woff'); 
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Lato-Regular-Latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Lato-Bold-Latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Lato-Bold-Latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
