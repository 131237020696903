@import (reference) "colours.less";
@import (reference) "fonts.less";
@import (reference) "page.less";
@import (reference) "titles.less";
@import (reference) "button.less";

@widget-light-underline-color: @accent-color;

@widget-padding: 5 * @mm-to-pixels;
@widget-margin: @page-panes-margin;
@widget-bgColor: @primary-color;

.widget-container(@color, @background-color, @underline-color) {
    padding: @widget-padding;
    margin-bottom: @widget-margin;
    border-radius: @button-border-radius;
    background-color: @background-color;
    color: @color;

    .widget-title-container {

        md-icon {
            fill: @color;
        }

        margin-bottom: @widget-padding;

        .widget-title {
            .font-medium;
            color: @color;

            .title-text::after {
                .accented(@underline-color);
            }
        }

        .widget-title-edit {
            fill: @color;
        }

        .widget-title-details-icon {
            md-icon {
                height: 3 * @mm-to-pixels;
                cursor: pointer;
            }
        }
    }
}

md-progress-linear.widget-progress-linear .md-container {
    background-color: white;
}

/*.widget-title-container md-progress-circular {
    margin-top: -10px;
}*/

.widget-container-dark {
    .widget-container(@color: white, @background-color: @widget-bgColor, @underline-color: white);

    .widget-title-container {
        .widget-title {
            font-weight: normal;
        }
    }
}

.widget-container-light {
    .widget-container(@color: black, @background-color: white, @underline-color: @widget-light-underline-color);
}
