@import (reference) "style.less";

#terms-and-condition {
    font-family: Bariol !important;

    span {
        font-family: Bariol !important;
        font-size: 15px !important;
    }

    p, .p {
        margin: 0 !important;
        margin-bottom: ceil(5 * @mm-to-pixels) !important;
    }


    .MsoNormalTable {
        border: none !important;

        td {
            border: none !important;
        }
    }
}

.licensing-agreement {
    //css for terms and conditions are defined in pt instead of px. For consistency, pt is used for this item
    padding-left: 15pt;
    padding-right: 15pt;
    padding-bottom: 25pt;
}
