@import (reference) "colours.less";

.gauge-background-circle {
    stroke: @tertiary-text-color;
}

.gauge-primary-arc {
    stroke: @primary-color;
}

.gauge-secondary-arc {
    stroke: @warning-color;
}