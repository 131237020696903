@import (reference) "colours.less";
@import (reference) "page.less";

.accented(@underline-color, @margin-underneath: 2) {
    width: ceil(6 * @mm-to-pixels);
    height: ceil(0.5 * @mm-to-pixels);
    content: '';
    display: block;
    background: @underline-color;
    margin-top: ceil(@margin-underneath * @mm-to-pixels);
}