@import (reference) "fonts.less";
@import (reference) "animations.less";
@import (reference) "tooltip.less";

@bar-chart-item-color: #E7E4F7;
@bar-chart-item-hover-color: #C6C6CE;

@bar-chart-item-animation-duration: 1s;
@bar-chart-item-animation-delay: 0.2s;

.bar-chart {
    padding: 20px 0;
    max-width: 264px;
    margin:auto;
}

.bar-chart-item {
    background-color: @bar-chart-item-color;
    width: 10px;
    transition: 0.1s background-color;
    cursor: pointer;
}

.bar-chart-item:hover {
    background-color: @bar-chart-item-hover-color;
}

.bar-chart-caption {
    min-width: 25px;
    text-align: center;
    padding-top: 15px;
    font-size: @font-small-size;
}

/* Tooltip styling */
md-tooltip.bar-chart-tooltip .md-content {
    .tooltip;
}

.bar-chart-column {
    .grow-up(@bar-chart-item-animation-duration, @bar-chart-item-animation-delay, bar-chart-item);
}
