@import (reference) "page.less";
@import (reference) "colours.less";

/* There will be a two dots at the top and bottom of the line unless the height is an odd number. */
.group-box-divider {
    .vertical-divider;
}

.group-box {

    margin-bottom: 2 * @mm-to-pixels;

    .group-item {
        padding-left: 5 * @mm-to-pixels;
        padding-right: 5 * @mm-to-pixels;
    }
    
    .group-item-left {
        .group-item;
        padding-left: 0;
    }

    .divider {
        .vertical-divider;
    }

}
