@import (reference) "fonts.less";
@import (reference) "colours.less";
@import (reference) "page.less";
@import (reference) "style.less";

@button-text-color: #fff;
@button-height: 8 * @mm-to-pixels;
@button-border-color: @button-text-color;
@button-border-radius: @default-border-radius;
@button-min-width: ceil(27 * @mm-to-pixels);
@button-padding: ceil(2.4 * @mm-to-pixels);

.button {
    color: @button-text-color;
    text-transform: none;
    min-width: @button-min-width;
    font-size: @font-normal-size * 1.1;
    height: @button-height;
    line-height: @button-height;
    padding: 0 @button-padding;
    border-radius: @button-border-radius;
    margin-left: 0;     // Allow left alignment.
    margin-right: 0;
}

.dark-button {
    .button;
    background-color: @accent-color !important;
    font-weight:bold;
}

.light-button {
    .button;
    background-color: white;
    font-weight:bold;
}

.transparent-button {
    .button;
    background-color: transparent !important;
    border: .5 * @mm-to-pixels solid @button-border-color;
}

.transparent-dark-border-button {
    .button;
    color: @accent-color;
    border: .5 * @mm-to-pixels solid @accent-color;
    line-height: normal;
}

.button-container {
    display: block;
    margin-top: 4 * @mm-to-pixels;
}

.confirm-button {
    background-color: @confirm-color !important;
}

.button-horizontal-margin {
    margin-right: 10px;
}
.button-medium-margin {
    padding: 5px 15px 5px 15px;
}