.carousel-container {
    margin: auto;
}

.carousel-container img {
    display: none;
}

.carousel .slick-center img {
    opacity: 1;
    transform: scale(1.2);
    -webkit-filter: none;
    z-index: 100;
}

.carousel img {
    display: block;
    transition: all 300ms ease;
    opacity: 0.5;
    background-position: center;
    background-repeat: no-repeat;
    -webkit-filter: grayscale(1);
    background-size: auto 100%;
    width: 100%;
    height: 120px;
}

.carousel-container {
    width: 224px;
}

.slick-track {
    padding-top: 20px;
}

.slick-list {
    padding: 0 !important;
}

.slick-prev:before, .slick-next:before {
    color: black;
    // Prevent loading of slick font
    font-family: sans-serif;
}

.carousel-label {
    text-align: center;
    opacity: 0;
    padding-top: 15px;
}

.slick-center > .carousel-label {
    opacity: 1;
}

.slick-loading .slick-list {
    // Override default 'loading' gif
    background: none;
}
