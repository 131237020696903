@import (reference) "page.less";

@section-to-margin: 6 * @mm-to-pixels;
@text-box-border-color: #a9a9a9;
@field-height: ceil(7 * @mm-to-pixels);

#login-container {

    .bullet-point-container {
        md-icon {
            height: 3 * @mm-to-pixels;
            fill: @primary-color;
        }

        padding-bottom: 1 * @mm-to-pixels;
    }
}

#cardholder-grid tbody tr{
    cursor:pointer;
}

.md-button[disabled]
{
    color:#a5a5a5;
}

