@import "debug.less";
@import "colours.less";
@import "fonts.less";
@import "page.less";
@import "button.less";
@import "calendar.less";
@import "customer.banner.less";
@import "progressindicator.less";
@import "menu.less";
@import "titles.less";
@import "widget.less";
@import "images.less";
@import "chart.bar.less";
@import "groupbox.less";
@import "customer.profile.less";
@import "animations.less";
@import "carousel.less";
@import "star.rating.less";
@import "recipe.less";
@import "decorators.less";
@import "spinner.less";
@import "chart.horizontal.bar.less";
@import "weeks.special.carousel.less";
@import "wizard.less";
@import "myprofile.wizard.less";
@import "groupbutton.less";
@import "google.maps.less";
@import "gauge.graph.less";
@import "slick-theme.less";
@import "feedback.less";
@import "checkbox.less";
@import "slider.less";
@import "shopping.basket.less";
@import "body.less";
@import "shopping.list.less";
@import "tooltip.less";
@import "validation.tooltip.less";
@import "login.less";
@import "form.field.less";
@import "forgot.password.less";
@import "radiobutton.less";
@import "banner.less";
@import "terms.and.conditions.less";
@import "date.picker.less";

@fade-in-animation: 1s ease-in-out opacity;
@dropdown-text-color: @accent-color;
@default-border-radius: ceil(.9 * @mm-to-pixels);

html {
    opacity: 1;
}

.overlay {
    opacity: 0;
}

body, select, textarea, input {
    .font-normal !important;
    font-family: @font-family !important;
}

input, select {
    .inputStyle;
    height: ceil(6.2 * @mm-to-pixels);
}

select:disabled {
    background-color: @tertiary-text-color;
    color: gray;
}

textarea {
    .inputStyle
}

.inputStyle {
    border: 1px solid @tertiary-text-color;
    border-radius: @button-border-radius;
    padding: 0;
    padding-left: 5px;
    padding-right: 5px;
    outline: none;
    color: @dropdown-text-color;
    font-weight: bold;
    box-sizing: border-box;
}

.separate-next-input {
    margin-right: 7px;
}

.small-size-input input {
    width: 100px !important;
    float:left;
    margin-right: 15px;
}

.small-size-input-last input {
    margin-right: 30px !important;   
}

.medium-size-input input {
    margin-right: 15px;
    float:left;
    width:160px !important;
}

select option {
    color: @dropdown-text-color;
    font-weight: bold;
}

body {
    background-color: @body-background-color !important;
    height: unset !important; /* Required otherwise the body's height is limit to the initial height of the window (in Chrome at least). */
}

hr {
    border: none;
    height: 1px;
    width: 100%;
    margin: 0;
    display: block;
    background-color: @body-background-color;
}

a {
    color: @hyperlink-color;
    text-decoration: none;
}

/* Used for large monetary values */
h1 {
    .font-extra-large;
    font-weight: normal;
    margin: 0;
    margin-bottom: ceil(1.5 * @mm-to-pixels);
}

/* Used for second largest monetary values */
h2 {
    .font-large;
    font-weight: normal;
    margin: 0;
    margin-bottom: 3 * @mm-to-pixels;
}

h3 {
    .font-medium-large;
    margin: 0;
    margin-bottom: 2 * @mm-to-pixels;
}

/* Generally used as the title for the widget's body */
h4 {
    .font-medium;
    margin: 0;
    margin-bottom: 4 * @mm-to-pixels;
}

br {
    display: block;
    margin: 0;
    margin-bottom: ceil(1 * @mm-to-pixels);
    content: '';
}

/* .p is used for divs that represents paragraphs */
p, .p {
    margin: 0;
    margin-bottom: ceil(5 * @mm-to-pixels);
}

p br {
    display: block;
    margin-bottom: 2 * @mm-to-pixels;
}

.section-break {
    margin-top: 7 * @mm-to-pixels;
    content: '';
}

.clickable {
    cursor: pointer;
    outline: none;
}

.fade-in.ng-hide, .fade-in.ng-enter {
    opacity: 0;
    transition: @fade-in-animation;
}

.fade-in.ng-hide-remove, .fade-in.ng-hide-add {
    transition: @fade-in-animation;
}

.fade-in.ng-enter.ng-enter-active, .fade-in.ng-hide-remove-active {
    opacity: 1;
}

.indented-container {
    padding-left: 3 * @mm-to-pixels;
    width: 100%;
    box-sizing: border-box;
}

md-progress-linear[disabled] {
    visibility: hidden;
}

md-progress-circular[disabled] {
    visibility: hidden;
}

.banner-slick {
    width: 250px;
    height: 250px;

    div {
        border-radius: 5px;
    }

    .slick-track {
        padding: 0;
    }
}

.divider {
    height: 1px;
    background-color: @divider-color;
}

md-dialog.md-browser-warning-theme {
    max-width: 600px;
}

.md-ripple-container div {
    background-color: @accent-color !important;
}

md-radio-group.md-default-theme.md-focused:not(:empty) .md-checked .md-container:before, md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
    background-color: @accent-color !important;
    opacity: 0.26 !important;
}
