@import (reference) "colours.less";
@import (reference) "style.less";

@suggestion-item-height: 2.5rem;
@input-height: 25px;
@department-icon-color: @accent-color;

#shopping-list-holder {
    position: relative;

    #suggestion-holder {
        position: relative;
    }

    #dvSuggestions {
        position: absolute;
        background-color: white;
        color: black;
        left: 0;
        right: 0;
        border-radius: 5px;
        z-index: 100;
        border: 1px solid @accent-color;
    }

    ul, li {
        list-style-type: none;
        list-style-position: inside;
        margin: 0;
        padding: 0;
    }

    .search-box {
        width: 100%;
        min-width: 264px;
    }

    .item-holder {
        margin-bottom: 2px;
        cursor: pointer;
        min-height: @suggestion-item-height;

        .item-name {
            width: 145px;
            word-wrap: break-word;
        }

        .add-icon {
            height: 10px;
        }
    }

    .item-holder:hover {
        background-color: @body-background-color;
    }

    .selected-list-container {
        margin-top: ceil(3 * @mm-to-pixels);
        margin-bottom: 20px;
        min-height: 250px;

        .department-contents {
            max-height: 1000px; //set a max hight value that can accomodate any practical number of items
            transition: max-height .2s ease-in;
            overflow-y: hidden;
        }

        .collapse {
            max-height: 0;
            transition: max-height .2s ease-out;
        }

        .department-holder {
            margin-bottom: ceil(2 * @mm-to-pixels);

            .department-header {
                background-color: white;
                color: black;
                border-radius: 3px;
            }

            .selected-item-list-containter {
                margin-left: 8px;
                margin-top: ceil(2 * @mm-to-pixels);
                margin-bottom: ceil(2 * @mm-to-pixels);
                margin-right: 4px;
                max-width: 168px;
                word-break: break-word;

                md-checkbox .md-icon {
                    border-color: white;
                    background-color: white;
                }

                md-checkbox.md-checked .md-icon:after {
                    border-color: @accent-color;
                }

                md-checkbox.md-default-theme.md-checked .md-icon {
                    border: white;
                }
            }

            .delete-icon {
                fill: white;
                cursor: pointer;
            }

            .badge {
                padding: 4px 7px;
                background-color: @badge-color;
                color: white;
                border-radius: 12px;
                margin-right: 10px;
                font-weight: bold;
                font-size: 12px;
                min-width: 8px;
                text-align: center;
            }
        }

        input {
            width: 40px;
            height: @input-height;
        }
    }

    .full-width {
        max-width: 250px !important;
    }


    md-icon {
        fill: @department-icon-color;
    }

    .icon {
        width: ceil(2.4 * @mm-to-pixels);
        margin-right: ceil(2 * @mm-to-pixels);
        margin-left: ceil(2 * @mm-to-pixels);
    }

    .first-icon {
        margin-right: 0;
    }

    .strike-through {
        span {
            text-decoration: line-through;
        }
    }

    .done-footer {
        margin-top: 10px;
        padding-right: 10px;
        background-color: white;
        font-weight: bold;
        text-align: right;
    }

    .shopping-list-footer {
        bottom: 0;
        margin-top: 20px;
        line-height: 20px;
        background-color: white;
        color: black;
        font-weight: bold;
        padding-left: 10px;
        padding-right: 10px;


        .vertical-middle-align {
            display: flex;
            align-items: center;
        }
    }

    .mCSB_inside > .mCSB_container {
        margin-right: 5px !important;
    }

    .mCSB_scrollTools {
        right: -9px !important;
    }

    .mCSB_scrollTools .mCSB_draggerContainer {
        right: 6px !important;
    }

    .mCSB_scrollTools .mCSB_draggerRail {
        background-color: transparent;
    }

    //disabled animation when an item is completed, the animation will be fixed in another PBI
    /*
    .animate-repeat.ng-move,
    .animate-repeat.ng-enter,
    .animate-repeat.ng-leave {
        transition: all linear .5s;
    }

    .animate-repeat.ng-leave.ng-leave-active,
    .animate-repeat.ng-move,
    .animate-repeat.ng-enter {
        opacity: 0;
    }

    .animate-repeat.ng-leave,
    .animate-repeat.ng-move.ng-move-active,
    .animate-repeat.ng-enter.ng-enter-active {
        opacity: 1;
    }
    */
}
