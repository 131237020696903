@import (reference) "fonts.less";
@import (reference) "colours.less";

.weeks-special-carousel-container {
    margin: auto;
    width: ceil(45 * @mm-to-pixels);
    text-align: center;

    .header {
        .font-medium;

        .header-arrow {
            height: ceil(2 * @mm-to-pixels);
            width: 15px;
            color: @accent-color;
            vertical-align: middle;
        }
    }

    .weeks-special-carousel {
        .slick-center img {
            opacity: 1;
            z-index: 100;
            filter: none;
        }

        .blue-badge {
            width: ceil(16 * @mm-to-pixels);
            height: ceil(16 * @mm-to-pixels);
            background-color: @primary-color;
            color: white;
            border-radius: 50%;
            display: table-cell;
            text-align: center;
            font-size: @font-small-size;
            position: absolute;
            top: 40px;
            margin-left: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: ceil(3 * @mm-to-pixels);
            box-sizing: border-box;
        }
    }

    .footer {
        padding-top: 5 * @mm-to-pixels;
    }
}
