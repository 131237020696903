/* Blue colour for underline, buttons and backgrounds for some widgets */
/* Light blue colour used to fill the glyphs such as adult, child, pet */
/* Widget, logo, footer */
/* Green (used for up arrows) */
/* Red */
/* Neutral (figure unchanged color - for arrows)*/
/* Dark gray */
/* Light gray */
/* Light gray */
/* general unfilled-bar background, some table header backgorund, show-more/show-less arrows*/
/* This is the smallest size that is legible for the the Bariol font. */
@font-face {
  font-family: 'Bariol';
  font-weight: normal;
  font-style: normal;
  src: url("/fonts/Bariol_Regular.woff") format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-weight: bold;
  font-style: normal;
  src: url("/fonts/Bariol_Bold.woff") format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-weight: lighter;
  font-style: normal;
  src: url("/fonts/Bariol_Light.woff") format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-weight: 100;
  font-style: normal;
  src: url("/fonts/Bariol_Thin.woff") format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-weight: normal;
  font-style: italic;
  src: url("/fonts/Bariol_Regular_Italic.woff") format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-weight: bold;
  font-style: italic;
  src: url("/fonts/Bariol_Bold_Italic.woff") format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-weight: lighter;
  font-style: italic;
  src: url("/fonts/Bariol_Light_Italic.woff") format('woff');
}
@font-face {
  font-family: 'Bariol';
  font-weight: 100;
  font-style: italic;
  src: url("/fonts/Bariol_Thin_Italic.woff") format('woff');
}
.font-small {
  font-size: 14px;
}
.font-normal {
  font-size: 15px;
}
.font-medium {
  font-weight: bold;
  font-size: 16px;
}
.font-medium-large {
  font-weight: bold;
  font-size: 19px;
}
.font-large {
  font-size: 29px;
}
.font-extra-large {
  font-size: 48px;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Lato-Regular-Latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Lato-Regular-Latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Lato-Bold-Latin-ext.woff') format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/Lato-Bold-Latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* 4.8 = 1000 / 205mm (based on A3 printout). Used when measuring the sizes from the design document and translating them to pixels. */
/* Spacing between widgets */
.content-container {
  justify-content: center;
  display: flex;
}
/* Centered content for the page */
.content {
  width: 984px;
  margin-left: 12px;
  margin-right: 12px;
}
.page-image-banner-height {
  height: 264px;
}
@media only screen and (max-width: 600px) {
  .page-image-banner-height {
    display: none;
  }
}
/* Vertical left pane - assumed to be adjunct to .page-right-pane */
.page-left-pane {
  margin-top: 12px;
}
/* Vertical left pane - assumed to be adjunct to .page-right-pane */
.page-full-width-pane {
  margin-top: 12px;
}
/* Vertical right pane - assumed to be adjunct to .page-left-pane */
.page-right-pane {
  margin-top: 12px;
  margin-left: 12px;
  min-width: 312px;
}
@media (min-width: 960px) {
  #page-right-pane {
    width: 312px;
  }
}
@media only screen and (max-width: 600px) {
  #login-container {
    flex-direction: column;
  }
  #register-container {
    flex-direction: column;
  }
  #password-container {
    flex-direction: column;
  }
  .contactus-container .group-box {
    max-width: 350px;
    margin: 0 auto;
  }
  .content {
    /*
        width: 500px;*/
    margin-left: 0;
    margin-right: 0;
  }
  #register-section {
    max-width: 350px;
    flex: 1 1 auto;
    margin: 0 auto;
  }
  #login-section {
    max-width: 350px;
    flex: 1 1 auto;
    margin: 0 auto;
  }
  #newAccount-section {
    max-width: 350px;
    flex: 1 1 auto;
    margin: 0 auto;
  }
  #link-section {
    max-width: 350px;
    flex: 1 1 auto;
    margin: 0 auto;
  }
  #reset-section {
    max-width: 350px;
    flex: 1 1 auto;
    margin: 0 auto;
  }
  #reset-section .resetQuestion {
    width: 192px;
  }
  #resend-section {
    max-width: 350px;
    flex: 1 1 auto;
    margin: 0 auto;
  }
  #resend-section .email-button-holder {
    width: 132px !important;
  }
  .contact-label {
    width: 300px !important;
    margin-left: 40px;
  }
  .contact-p {
    text-align: center;
  }
  .contact-input {
    margin: 0 auto;
    float: none !important;
  }
  .contact-buttons {
    margin: 0 auto !important;
    display: block;
  }
  .contact-input span.tooltip-container span {
    display: block;
  }
  .contact-input-message {
    top: 25px !important;
  }
  .contact-input-message span {
    display: block;
  }
  .widget-container-light {
    padding: 0px !important;
  }
  .linkcard-input span {
    display: block;
  }
  .newcardholder {
    margin-bottom: 10px;
  }
  .newcardholder input {
    margin-bottom: 10px;
  }
  .setting-password .existPdField .form-field {
    flex-direction: column !important;
  }
  .setting-password .newPdField .form-field {
    flex-direction: column !important;
  }
  .setting-password .confirmPdField .form-field {
    flex-direction: column !important;
  }
  .setting-password .form-field .button-holder {
    width: 100px;
  }
  .setting-question .questionField .form-field {
    flex-direction: column !important;
  }
  .setting-question .questionField .form-field .label-field {
    width: 250px !important;
  }
  .setting-question .questionField .form-field select {
    width: 250px !important;
  }
  .setting-question .answerField .form-field {
    flex-direction: column !important;
  }
  .setting-question .answerField .form-field .label-field {
    width: 250px !important;
  }
  .setting-question .form-field .button-holder {
    width: 120px;
  }
  .setting-pin {
    max-width: 350px;
    margin: 0 auto;
  }
  .setting-question {
    max-width: 350px;
    margin: 0 auto;
  }
  .setting-password {
    max-width: 350px;
    margin: 0 auto;
  }
  .setting-pin .cardNumberField .form-field {
    flex-direction: column !important;
  }
  .setting-pin .cardNumberField .form-field .label-field {
    width: 255px !important;
  }
  .setting-pin .pinField .form-field {
    flex-direction: column !important;
  }
  .setting-pin .newPinField .form-field {
    flex-direction: column !important;
  }
  .setting-pin .confirmPinField .form-field {
    flex-direction: column !important;
  }
  .setting-pin .pinField md-checkbox .md-container {
    left: auto !important;
  }
  .setting-pin .pinField md-checkbox {
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    width: 220px;
  }
  .setting-pin form-field .button-holder {
    width: 120px;
  }
  .registration-name .form-field {
    flex-direction: column !important;
  }
  .registration-email .form-field {
    flex-direction: column !important;
  }
  .registration-mobile .form-field {
    flex-direction: column !important;
  }
  .registration-phone .form-field {
    flex-direction: column !important;
  }
  .registration-address .form-field {
    flex-direction: column !important;
  }
  .registration-password .form-field {
    flex-direction: column !important;
  }
  .registration-confirmPassword .form-field {
    flex-direction: column !important;
  }
  .registration-question .form-field {
    flex-direction: column !important;
  }
  .registration-answer .form-field {
    flex-direction: column !important;
  }
  .registration-pin .form-field {
    flex-direction: column !important;
  }
  .registration-confirmPin .form-field {
    flex-direction: column !important;
  }
  .registration-charity .form-field {
    flex-direction: column !important;
  }
  .formOne-date .form-field {
    flex-direction: column !important;
  }
  .formOne-email .form-field {
    flex-direction: column !important;
  }
  .formOne-mobile .form-field {
    flex-direction: column !important;
  }
  .formOne-phone .form-field {
    flex-direction: column !important;
  }
  .formOne-fax .form-field {
    flex-direction: column !important;
  }
  .formOne-tradingAs .form-field {
    flex-direction: column !important;
  }
  .formOne-abn .form-field {
    flex-direction: column !important;
  }
  .formOne-owner .form-field {
    flex-direction: column !important;
  }
  .formOne-manager .form-field {
    flex-direction: column !important;
  }
  .formOne-newBusiness .form-field {
    flex-direction: column !important;
  }
  .formOne-address .form-field {
    flex-direction: column !important;
  }
  .formOne-postcode .form-field {
    flex-direction: column !important;
  }
  .formOne-state .form-field {
    flex-direction: column !important;
  }
  .formOne-date .control-field .k-widget {
    width: 250px !important;
  }
  .formOne-state .form-field input {
    width: 250px !important;
  }
  .formOne-email .form-field input {
    width: 250px !important;
  }
  .formOne-mobile .form-field input {
    width: 250px !important;
  }
  .formOne-phone .form-field input {
    width: 250px !important;
  }
  .formOne-fax .form-field input {
    width: 250px !important;
  }
  .formOne-tradingAs .form-field input {
    width: 250px !important;
  }
  .formOne-abn .form-field input {
    width: 250px !important;
  }
  .formOne-owner .form-field input {
    width: 250px !important;
  }
  .formOne-manager .form-field input {
    width: 250px !important;
  }
  .formOne-newBusiness .form-field input {
    width: 250px !important;
  }
  .formOne-address .form-field input {
    width: 250px !important;
  }
  .formOne-postcode .control-field {
    width: 250px !important;
  }
  .formOne-postcode .control-field input {
    width: 80px !important;
  }
  .formOne-state .form-field .control-field {
    width: 250px !important;
  }
  .formOne-state .form-field .control-field select {
    width: 80px !important;
  }
  .titleField {
    width: 100px;
    display: block;
  }
  .firstnameField input {
    width: 250px !important;
    display: block;
  }
  .mobileField input {
    width: 250px !important;
  }
  .phoneField input {
    width: 250px !important;
  }
  .postcodeField input {
    width: 250px !important;
  }
  .passwordField input {
    width: 250px !important;
  }
  .confirmPasswordField input {
    width: 250px !important;
  }
  .promoCodeField input {
    width: 250px !important;
  }
  .titleField {
    margin-bottom: 20px;
  }
  .answerField input {
    width: 250px !important;
  }
  .lastnameField input {
    width: 250px !important;
    display: block;
  }
  .registration-email input {
    min-width: 250px !important;
  }
  .registration-address .streetNoField input {
    min-width: 100px !important;
    display: block;
    margin-bottom: 20px;
  }
  .registration-address .streetNameField input {
    min-width: 250px !important;
    display: block;
    margin-bottom: 20px;
  }
  .registration-address .suburbField input {
    min-width: 250px !important;
    display: block;
    margin-bottom: 20px;
  }
  .registration-address .postcodeField input {
    min-width: 100px !important;
    display: block;
    margin-bottom: 20px;
  }
  .registration-pin input {
    min-width: 250px !important;
  }
  .registration-confirmPin input {
    min-width: 250px !important;
  }
  .registration-name .label-field {
    width: 250px !important;
    margin-top: 20px;
  }
  .registration-email .label-field {
    width: 250px !important;
    margin-top: 20px;
  }
  .registration-mobile .label-field {
    width: 250px !important;
  }
  .registration-phone .label-field {
    width: 250px !important;
  }
  .registration-address .label-field {
    width: 250px !important;
    margin-top: 20px;
  }
  .registration-password .label-field {
    width: 250px !important;
  }
  .registration-confirmPassword .label-field {
    width: 250px !important;
  }
  .registration-question .label-field {
    width: 250px !important;
    margin-top: 20px;
  }
  .registration-answer .label-field {
    width: 250px !important;
  }
  .registration-address .control-field {
    margin-left: 15px;
  }
  .registration-pin .label-field {
    width: 250px !important;
  }
  .registration-confirmPin .label-field {
    width: 250px !important;
  }
  .registration-charity .label-field {
    width: 250px !important;
  }
  .registration-charity .charityField {
    max-width: 250px !important;
  }
  .registration-question .questionField {
    max-width: 250px !important;
  }
  .registration-banner .info-banner {
    min-height: 50px;
    line-height: 1.5em;
    max-width: 300px;
    margin: 0 auto;
  }
  .formone-banner .info-banner {
    min-height: 50px;
    line-height: 1.5em;
    max-width: 330px;
    margin: 20px auto;
  }
  .formOne-date .label-field {
    width: 250px !important;
  }
  .formOne-email .label-field {
    width: 250px !important;
  }
  .formOne-mobile .label-field {
    width: 250px !important;
  }
  .formOne-phone .label-field {
    width: 250px !important;
  }
  .formOne-fax .label-field {
    width: 250px !important;
  }
  .formOne-tradingAs .label-field {
    width: 250px !important;
  }
  .formOne-abn .label-field {
    width: 250px !important;
  }
  .formOne-owner .label-field {
    width: 250px !important;
  }
  .formOne-manager .label-field {
    width: 250px !important;
  }
  .formOne-newBusiness .label-field {
    width: 250px !important;
  }
  .formOne-address .label-field {
    width: 250px !important;
  }
  .formOne-postcode .label-field {
    width: 250px !important;
  }
  .formOne-state .label-field {
    width: 250px !important;
  }
  .linkcardRegistration form {
    max-width: 350px;
    margin: 0 auto;
  }
  .shoppingSummary .widget-container-light {
    max-width: 350px;
    margin: 0 auto !important;
  }
  .shoppingBasket .widget-container-dark {
    max-width: 350px;
    margin: 0 auto !important;
  }
  .shoppingBasket {
    margin-left: 0 !important;
  }
  .profileDetail form {
    max-width: 350px;
    margin: 0 auto;
  }
  .profileCharity .widget-container-dark {
    max-width: 350px;
    margin: 0 auto !important;
  }
  .profileCharity .widget-container-light {
    max-width: 350px;
    margin: 0 auto !important;
  }
  .profileCharity {
    margin-left: 0 !important;
  }
  .dashboardSummary .widget-container-light {
    max-width: 350px;
    margin: 0 auto;
  }
  .dashboardShopping .widget-container-dark {
    max-width: 350px;
    margin: 0 auto;
  }
  .dashboardShopping {
    margin-left: 0px;
  }
  .profileDetail .primaryCardholder {
    flex-direction: column !important;
  }
  .profileDetail .primaryCardholder .buttons {
    flex-direction: row !important;
  }
  .savebtn {
    width: 100px;
    margin-left: 100px;
  }
  .page-footer .footer-text {
    display: none;
  }
  .mereo-footer-url a {
    display: none;
  }
  .my-profile-wizard-step-height {
    height: 190px !important;
    flex-direction: row;
  }
  #my-profile-wizard {
    max-height: 500px !important;
    display: block;
  }
  .wizard-diets {
    flex-direction: row !important;
  }
  .wizard-diets .dietSection md-checkbox {
    width: 120px !important;
  }
  .wizard-diets .wizard-input-container {
    padding-left: 0px !important;
    flex-direction: column !important;
  }
  .wizard-diets-container {
    height: 180px !important;
  }
  .my-profile-step-container {
    margin-top: 0px !important;
  }
  .wizard-step-next {
    transform: translateX(-100%) !important;
  }
  .personal-info-container .info-item .icon {
    width: 30px !important;
  }
  .virturalcard-banner .info-banner {
    height: 75px !important;
    line-height: 25px !important;
  }
  .body-content {
    background-color: #fff;
  }
  .dashboard-barcode #barcode {
    height: 120px;
    width: 200px;
  }
  .virtual-option {
    margin: 0 auto;
    max-width: 350px;
  }
  .virtual-option button {
    width: 250px;
    margin: 10px auto !important;
    display: block;
  }
  .virtual-registration {
    max-width: 350px;
    margin: 0 auto !important;
  }
  .virtual-registration-banner .info-banner {
    min-height: 50px;
    line-height: 1.5em;
    max-width: 350px;
    margin: 0 auto;
  }
  .virtual-registration-email input {
    min-width: 250px !important;
  }
  .virtual-registration-person .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-person .label-field {
    width: 250px !important;
    margin-top: 0px;
  }
  .virtual-registration-store .form-field {
    flex-direction: column !important;
    min-width: 250px;
  }
  .virtual-registration-store .label-field {
    width: 250px !important;
    margin-top: 20px;
  }
  .virtual-registration-email .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-email .label-field {
    width: 250px !important;
    margin-top: 0px;
  }
  .virtual-registration-mobile .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-mobile .label-field {
    width: 250px !important;
  }
  .virtual-registration-phone .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-phone .label-field {
    width: 250px !important;
  }
  .virtual-registration-postcode .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-postcode .label-field {
    width: 250px !important;
  }
  .virtual-registration-password .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-password .label-field {
    width: 250px !important;
    margin-top: 0px;
  }
  .virtual-registration-title {
    margin-bottom: 0px;
    margin-top: 20px;
  }
  .virtual-registration-confirmPassword .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-promotion .label-field {
    width: 250px !important;
  }
  .virtual-registration-promotion .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-confirmPassword .label-field {
    width: 250px !important;
  }
  .virtual-registration-question .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-question .label-field {
    width: 250px !important;
    margin-top: 20px;
  }
  .virtual-registration-question .questionField {
    max-width: 250px !important;
  }
  .virtual-registration-answer .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-answer .label-field {
    width: 250px !important;
  }
  .virtual-registration-pin .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-pin .label-field {
    width: 250px !important;
  }
  .virtual-registration-pin input {
    width: 250px !important;
  }
  .virtual-registration-confirmPin .form-field {
    flex-direction: column !important;
  }
  .virtual-registration-confirmPin .label-field {
    width: 250px !important;
  }
  .virtual-registration-confirmPin input {
    width: 250px !important;
  }
  .virtual-registration-emailSubscription,
  .virtual-registration-smsSubscription {
    width: 350px;
    display: block;
    margin: 0 auto;
  }
  .recaptchaCheck {
    width: 300px;
    display: block;
    margin: 0 auto;
  }
  .Virtual-registration-button {
    width: 250px;
    display: block;
    margin: 0 auto !important;
  }
  .registration-complete {
    max-width: 350px;
    margin: 0 auto;
  }
  .registration-complete-button {
    display: block;
    width: 250px;
    margin: 0 auto !important;
  }
  .termsandcondition {
    max-width: 350px;
    margin: 0 auto;
  }
  .licensingagreement {
    max-width: 350px;
    margin: 0 auto;
  }
  .reset-password {
    max-width: 350px;
    margin: 0 auto;
  }
  .resend-password {
    max-width: 350px;
    margin: 0 auto;
  }
  .registerPanel {
    max-width: 350px;
    margin: 0 auto;
  }
  .registration-detail {
    max-width: 350px;
    margin: 0 auto;
  }
  .settingPanel {
    max-width: 350px;
    margin: 0 auto;
  }
  .primaryCardholderPanel {
    max-width: 350px;
    margin: 0 auto;
  }
  .virtualInitialPanel {
    max-width: 350px;
    margin: 0 auto;
  }
  .virtualCompletePanel {
    max-width: 350px;
    margin: 0 auto;
  }
  .cardShowPanel {
    max-width: 350px;
    margin: 0 auto;
  }
  .profile-gender-edit .group-buttons {
    flex-direction: column !important;
  }
  .resend-password-form .label-field {
    width: 0px !important;
  }
}
@media only screen and (min-width: 1280px) {
  #login-container {
    flex-direction: row;
  }
  #register-container {
    flex-direction: row;
  }
  #password-container {
    flex-direction: row;
  }
  .content {
    width: 1000px;
    margin-left: 12px;
    margin-right: 12px;
  }
  #register-section {
    max-width: 460px;
    flex: 1 1 auto;
    float: right;
  }
  #login-section {
    max-width: 460px;
    flex: 1 1 auto;
    float: left;
  }
  #newAccount-section {
    max-width: 460px;
    flex: 1 1 auto;
    float: left;
  }
  #link-section {
    max-width: 460px;
    flex: 1 1 auto;
    float: right;
  }
  #reset-section {
    max-width: 460px;
    flex: 1 1 auto;
    float: right;
  }
  #resend-section {
    max-width: 460px;
    flex: 1 1 auto;
    float: left;
  }
}
.linkcard-input {
  width: 192px;
}
#login-container {
  min-height: 350px;
}
#register-container {
  min-height: 380px;
}
#password-container {
  min-height: 350px;
}
.page-footer {
  margin-top: 24px;
  background-color: #ED2025;
  color: white;
  text-align: center;
  min-height: 50px;
}
.page-footer .mereo-footer-title {
  font-size: 16.8px;
  font-weight: bold;
  text-align: center;
}
.footer-text {
  display: block;
  float: right;
  padding-top: 15px;
  margin-bottom: 0px !important;
  font-size: 13px;
}
.footer-text p {
  margin: 15px auto 0px auto !important;
}
.mereo-footer-url {
  display: block;
  float: left;
  padding-top: 15px;
  margin-bottom: 0px !important;
  font-size: 13px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  margin-left: 20px;
}
.mereo-footer-url a {
  text-decoration: none;
  color: white;
}
.mereo-footer-url a:active {
  text-decoration: none;
  color: white;
}
.mereo-footer-url a:hover {
  text-decoration: none;
  color: white;
}
.mereo-footer-url a:after {
  text-decoration: none;
  color: white;
}
/* There will be a two dots at the top and bottom of the line unless the height is an odd number. */
.vertical-divider {
  background: url('/content/images/divider-circle.svg') repeat;
  width: 2px;
  /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
}
.page-vertical-divider {
  background: url('/content/images/divider-circle.svg') repeat;
  width: 2px;
  /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
  margin-left: 24px;
  margin-right: 24px;
}
.horizontal-divider {
  background: url('/content/images/divider-circle.svg') repeat;
  height: 2px;
  /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
}
.page-horizontal-divider {
  background: url('/content/images/divider-circle.svg') repeat;
  height: 2px;
  /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
  margin-top: 24px;
  margin-bottom: 24px;
}
.cover {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  position: fixed;
  background-color: transparent;
}
.mereo-footer-logo {
  /* Image container */
}
.mereo-footer-logo div {
  background-repeat: no-repeat;
  background-size: contain;
  height: 40.8px;
  width: 200px;
  border-style: none;
  background-image: url('/content/images/logos/RadheWholesaleandRetailRewards/footer-logo.png');
  margin: 12px auto 0px auto;
}
.contact-label {
  width: 120px !important;
  display: block;
  float: left;
}
.contact-input {
  display: block;
  width: 260px !important;
  float: left;
}
.contact-input input {
  display: block;
  width: 260px !important;
  float: left;
}
.virturalcard-banner {
  margin-bottom: 40px;
  display: block;
}
.virturalcard-email .emailField input {
  width: 250px !important;
  min-width: 220px !important;
}
.virturalcard-widget {
  background-color: transparent !important;
}
#virtural_button button {
  margin: 0 auto;
  width: 100px;
  display: block;
  margin-top: 30px;
}
.button {
  color: #fff;
  text-transform: none;
  min-width: 130px;
  font-size: 16.5px;
  height: 38.4px;
  line-height: 38.4px;
  padding: 0 12px;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
}
.dark-button {
  color: #fff;
  text-transform: none;
  min-width: 130px;
  font-size: 16.5px;
  height: 38.4px;
  line-height: 38.4px;
  padding: 0 12px;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
  background-color: #0E8140 !important;
  font-weight: bold;
}
.light-button {
  color: #fff;
  text-transform: none;
  min-width: 130px;
  font-size: 16.5px;
  height: 38.4px;
  line-height: 38.4px;
  padding: 0 12px;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
  background-color: white;
  font-weight: bold;
}
.transparent-button {
  color: #fff;
  text-transform: none;
  min-width: 130px;
  font-size: 16.5px;
  height: 38.4px;
  line-height: 38.4px;
  padding: 0 12px;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
  background-color: transparent !important;
  border: 2.4px solid #fff;
}
.transparent-dark-border-button {
  color: #fff;
  text-transform: none;
  min-width: 130px;
  font-size: 16.5px;
  height: 38.4px;
  line-height: 38.4px;
  padding: 0 12px;
  border-radius: 5px;
  margin-left: 0;
  margin-right: 0;
  color: #0E8140;
  border: 2.4px solid #0E8140;
  line-height: normal;
}
.button-container {
  display: block;
  margin-top: 19.2px;
}
.confirm-button {
  background-color: #0E8140 !important;
}
.button-horizontal-margin {
  margin-right: 10px;
}
.button-medium-margin {
  padding: 5px 15px 5px 15px;
}
/* Kendo calendar */
.k-calendar {
  border-radius: 5px;
  border: 0;
}
.k-calendar .footer {
  font-size: 14px;
  clear: both;
  background-color: #ED2025;
  color: white;
  padding: 12px;
}
.k-calendar-with-border {
  border: 1px solid #707070;
}
.k-calendar table.k-content {
  padding: 0 15px 10px 15px;
}
/* Fixes for Firefox and Edge (ugh) */
.calendar-fix.k-calendar table.k-content {
  padding: 0;
}
.calendar-fix.k-calendar {
  margin: -10px;
}
@-moz-document url-prefix() {
  .k-calendar table.k-content {
    padding: 0;
  }
  .k-calendar {
    margin: -10px;
  }
}
.k-calendar div.k-header {
  margin: 10px 10px 0 10px;
}
/* Arrow icons */
.k-calendar .k-icon.k-i-arrow-w {
  background-position: 0 -48px !important;
}
.k-calendar .k-icon.k-i-arrow-e {
  background-position: 0 -16px !important;
}
/* Hide header */
.k-calendar .k-header {
  background: transparent;
}
/* Header text colour */
.k-calendar .k-header .k-link {
  color: #A7B2BB;
}
/* Use normal cursor for 'month' header link */
.k-calendar .k-header .k-link.k-nav-fast {
  cursor: default;
}
/* Previous and next arrow links */
.k-calendar .k-header a.k-link.k-nav-next.k-state-hover,
.k-calendar .k-header a.k-link.k-nav-prev.k-state-hover {
  border-radius: initial;
  background-color: #E9EAED;
}
/* Day of week header */
.k-calendar .k-content thead tr th {
  color: #657076;
  font-weight: bold;
  text-align: center;
}
/* Default day style */
.k-calendar .k-link div {
  color: #A7B2BB;
  font-weight: bold;
  border-bottom: transparent solid 3px;
}
/* Other month day style */
.k-calendar .k-other-month .k-link div {
  color: #E9EAED;
}
/* Override today style */
.k-calendar .k-today {
  background-color: initial;
}
/* Today day style */
.k-calendar .k-today .k-link div {
  border-bottom: #0E8140 solid 3px;
}
/* Override today focused/active style */
.k-calendar .k-today.k-state-focused,
.k-calendar .k-today:active,
.k-calendar td.k-state-focused,
.k-calendar td.k-state-selected.k-state-focused {
  box-shadow: none;
}
/* Highlighted day style */
.k-calendar .k-link .day-highlight {
  color: #14b85b;
  background-color: initial;
  border-radius: initial;
  border-bottom: #14b85b solid 3px;
}
.k-calendar .k-other-month.calendar-highlight .k-link div {
  color: #A7B2BB;
}
/* Fixes issue with animation with rounded corners */
.k-calendar table.k-content {
  background: transparent;
}
.k-calendar td.calendar-highlight {
  border-radius: 0;
  background-color: rgba(14, 129, 64, 0.1);
}
.k-calendar td.calendar-highlight-start-date {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.k-calendar td.calendar-highlight-end-date {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
/* Override colour when item selected */
.k-calendar td.k-state-selected,
.k-calendar td.k-state-selected.k-state-hover {
  background-color: rgba(14, 129, 64, 0.1);
}
/* Hide the date picker icon. It does not match the styling of the application. */
.k-i-calendar {
  visibility: hidden;
}
.shopping-calendar-widget {
  background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)), url('/content/images/calendar_background.jpg');
  background-size: cover;
}
.dashboard-banner {
  /*.image-darken(url('/content/images/banner-vegetables.jpg'));*/
  background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)), url('/content/images/logos/RadheWholesaleandRetailRewards/banner-vegetables.jpg');
}
.customer-profile-banner {
  /*.image-darken(url('/content/images/banner-strawberries.jpg'));*/
  background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)), url('/content/images/logos/RadheWholesaleandRetailRewards/banner-strawberries.jpg');
}
.shopping-banner {
  /*.image-darken(url('/content/images/banner-blueberries.jpg'));*/
  background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)), url('/content/images/logos/RadheWholesaleandRetailRewards/banner-blueberries.jpg');
}
.default-banner {
  /*.image-darken(url('/content/images/banner-landing.jpg'));*/
  background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)), url('/content/images/logos/RadheWholesaleandRetailRewards/banner-landing.jpg');
}
.circular-img {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  border: 4.8px solid white;
}
.customer-img-container {
  height: 96px;
  width: 96px;
  position: relative;
  top: 0;
  left: 0;
}
.customer-img {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  border: 4.8px solid white;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #C4CCDF;
  object-fit: cover;
}
img.customer-img {
  display: none;
}
/* Image load success so display it */
img[src].customer-img {
  display: initial;
}
.company-img {
  height: 96px;
  border-radius: 5px;
  display: block;
  border: 4.8px solid white;
  background-color: white;
}
.customer-info-container {
  padding-bottom: 33.6px;
}
.customer-info {
  color: white;
  padding-left: 24px;
  font-family: Lato;
}
.customer-info .info-date-line {
  text-transform: uppercase;
  font-size: 9.6px;
  font-weight: bold;
}
.customer-info .info-customer-name-line {
  font-size: 38.4px;
  vertical-align: middle;
  line-height: 72px;
  font-weight: bold;
}
.customer-info .points-background {
  background-color: black;
  border-radius: 38.4px;
  padding: 4.8px 14.4px;
  font-size: 14.4px;
  font-family: Bariol;
}
.customer-info .points-text {
  padding: 4.8px;
  font-size: 14.4px;
  font-family: Bariol;
}
.banner-container {
  padding-bottom: 44px;
  color: white;
}
.banner-container .title {
  font-size: 50px;
  font-weight: bold;
}
.banner-container .subtitle {
  padding-top: 7px;
}
md-progress-linear.md-default-theme .md-bar,
md-progress-linear .md-bar {
  background-color: #0E8140;
}
.mereo-title {
  font-size: 21.6px;
  font-weight: bold;
  color: #0E8140;
  position: relative;
  top: -9.6px;
  text-align: center;
  display: none;
}
.mereo-merchant-logo {
  position: relative;
  height: 21.6px;
  top: -14.4px;
  min-height: 50px;
  /* Image container */
}
.mereo-merchant-logo div {
  background-repeat: no-repeat;
  background-size: contain;
  height: 48px;
  width: 200px;
  border-style: none;
  background-image: url('/content/images/logos/RadheWholesaleandRetailRewards/menu-logo.png');
  background-position: center;
  margin: 0 auto;
}
.mereo-merchant-logo-virtual {
  position: relative;
  height: 21.6px;
  top: -14.4px;
  min-height: 55px;
  background-color: #FFFFFF;
  margin: -15px -25px 20px -25px;
  /* Image container */
}
.mereo-merchant-logo-virtual div {
  background-repeat: no-repeat;
  background-size: contain;
  height: 48px;
  width: 200px;
  border-style: none;
  background-image: url('/content/images/logos/RadheWholesaleandRetailRewards/menu-logo.png');
  background-position: center bottom;
  margin: 0 auto;
}
.menu {
  background-color: #FFFFFF;
}
.menu label {
  color: #5c5c5c;
}
.menu a {
  color: #5c5c5c !important;
}
.menu-container {
  padding-top: 29px;
  /* padding-bottom: ceil(3 * @mm-to-pixels); */
}
.menu .menu-item label {
  cursor: pointer;
  display: inline-block;
  /* Must be padding so that the menu is clickable outside the bounds of the text. */
  padding-left: 29px;
  padding-right: 29px;
  font-size: 13px;
  font-weight: bold;
}
.menu .menu-item label:hover {
  color: #5c5c5c;
}
.menu .menu-item label.selected {
  color: #5c5c5c;
}
.menu .menu-item .underline {
  margin-left: 29px;
  width: 29px;
  height: 3px;
  content: '';
  display: block;
  background: #5c5c5c;
  margin-top: 5px;
}
.menu .menu-item a {
  color: #5c5c5c;
  font-size: 13px !important;
  font-weight: bold !important;
  cursor: pointer;
}
.menu .menu-item a:hover {
  color: #5c5c5c !important;
}
.menu .divider {
  width: 1px;
  height: 15px;
  /* Not ideal. Using 1rem did not really work either. */
  background-color: #707070;
}
.menu md-icon {
  height: 15px;
  margin: 0;
  margin-top: 1px !important;
  fill: #0E8140 !important;
}
.navbar {
  float: right;
}
@media only screen and (max-width: 600px) {
  .navbar {
    float: left;
  }
  .menu {
    padding-bottom: 15px;
  }
}
md-progress-linear.widget-progress-linear .md-container {
  background-color: white;
}
/*.widget-title-container md-progress-circular {
    margin-top: -10px;
}*/
.widget-container-dark {
  padding: 24px;
  margin-bottom: 12px;
  border-radius: 5px;
  background-color: #ED2025;
  color: white;
}
.widget-container-dark .widget-title-container {
  margin-bottom: 24px;
}
.widget-container-dark .widget-title-container md-icon {
  fill: white;
}
.widget-container-dark .widget-title-container .widget-title {
  font-weight: bold;
  font-size: 16px;
  color: white;
}
.widget-container-dark .widget-title-container .widget-title .title-text::after {
  width: 29px;
  height: 3px;
  content: '';
  display: block;
  background: white;
  margin-top: 10px;
}
.widget-container-dark .widget-title-container .widget-title-edit {
  fill: white;
}
.widget-container-dark .widget-title-container .widget-title-details-icon md-icon {
  height: 14.4px;
  cursor: pointer;
}
.widget-container-dark .widget-title-container .widget-title {
  font-weight: normal;
}
.widget-container-light {
  padding: 24px;
  margin-bottom: 12px;
  border-radius: 5px;
  background-color: white;
  color: black;
}
.widget-container-light .widget-title-container {
  margin-bottom: 24px;
}
.widget-container-light .widget-title-container md-icon {
  fill: black;
}
.widget-container-light .widget-title-container .widget-title {
  font-weight: bold;
  font-size: 16px;
  color: black;
}
.widget-container-light .widget-title-container .widget-title .title-text::after {
  width: 29px;
  height: 3px;
  content: '';
  display: block;
  background: #0E8140;
  margin-top: 10px;
}
.widget-container-light .widget-title-container .widget-title-edit {
  fill: black;
}
.widget-container-light .widget-title-container .widget-title-details-icon md-icon {
  height: 14.4px;
  cursor: pointer;
}
.fill-warning-color {
  fill: #ff3353;
}
.fill-success-color {
  fill: #a8d609;
}
.fill-neutral-color {
  fill: #ffbc00;
}
md-icon {
  min-height: 0;
  min-width: 0;
}
.svg-icon-size {
  vertical-align: top;
  height: 1em;
  width: 8px;
}
.svg-icon-size-large {
  vertical-align: top;
  height: 1em;
  width: 15px;
}
.fill-accent-color {
  fill: #0E8140;
}
.scale-icon-holder {
  cursor: pointer;
}
.scale-icon-holder .scale-icon {
  height: 20px;
}
.scale-icon-holder .scale-icon-text-holder {
  padding-left: 5px;
}
.scale-icon-holder .scale-icon-text-holder span {
  font-size: 15px;
  color: #0E8140;
}
.fb-share {
  cursor: pointer;
}
.fb-share .fb-action-icon {
  color: #0E8140;
  font-size: 20px;
}
.fb-share span {
  padding-left: 5px;
  font-size: 15px;
  color: #0E8140;
}
.bar-chart {
  padding: 20px 0;
  max-width: 264px;
  margin: auto;
}
.bar-chart-item {
  background-color: #E7E4F7;
  width: 10px;
  transition: 0.1s background-color;
  cursor: pointer;
}
.bar-chart-item:hover {
  background-color: #C6C6CE;
}
.bar-chart-caption {
  min-width: 25px;
  text-align: center;
  padding-top: 15px;
  font-size: 14px;
}
/* Tooltip styling */
md-tooltip.bar-chart-tooltip .md-content {
  font-size: 14px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.54);
  padding-left: 8px;
  padding-right: 8px;
  height: 32px;
  line-height: 32px;
  color: white;
  white-space: nowrap;
}
.bar-chart-column.ng-enter {
  /* Required so that angular adds animation classes */
  animation-duration: 1s;
}
.bar-chart-column.ng-enter .bar-chart-item {
  transform: scaleY(0);
}
.bar-chart-column.ng-enter-active .bar-chart-item {
  transform-origin: bottom;
  animation: growUp 1s;
}
.bar-chart-column.ng-enter-stagger {
  /* Applies a staggered effect to the animation */
  animation-delay: 0.2s;
  animation-duration: 0s;
}
/* There will be a two dots at the top and bottom of the line unless the height is an odd number. */
.group-box-divider {
  background: url('/content/images/divider-circle.svg') repeat;
  width: 2px;
  /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
}
.group-box {
  margin-bottom: 9.6px;
}
.group-box .group-item {
  padding-left: 24px;
  padding-right: 24px;
}
.group-box .group-item-left {
  padding-left: 24px;
  padding-right: 24px;
  padding-left: 0;
}
.group-box .divider {
  background: url('/content/images/divider-circle.svg') repeat;
  width: 2px;
  /* Fill: should really use @divider-color, but image needs to be injected for the colour to be overridden. */
}
.customer-profile-image {
  height: 148.8px;
  width: 148.8px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #C4CCDF;
  object-fit: cover;
}
.customer-profile-container {
  height: 148.8px;
  width: 148.8px;
  position: relative;
  top: 0;
  left: 0;
}
img.customer-profile-image {
  display: none;
}
/* Image load success so display it */
img[src].customer-profile-image {
  display: initial;
}
.profile-details-container {
  margin-top: 24px;
}
.customer-profile-img {
  height: 148.8px;
  width: 148.8px;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
}
.button-holder {
  margin-top: 19.2px;
  width: 148.8px;
}
.button-holder .choose-img-button {
  margin: 0;
}
.personal-info-container .pencil-icon {
  padding-left: 4.8px;
  padding-right: 4.8px;
  margin-left: 4.8px;
  cursor: pointer;
}
.personal-info-container .info-item {
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  align-items: center;
}
.personal-info-container .info-item .profile-icons {
  vertical-align: top;
  height: 1em;
}
.personal-info-container .info-item .name-input input {
  width: 35%;
  min-width: unset !important;
}
.personal-info-container .info-item input {
  width: 97%;
  min-width: 120px;
  color: #0E8140;
}
.personal-info-container .info-item .icon {
  padding-right: 15px;
  margin: auto;
  text-align: center;
}
.personal-info-container .info-item .icon i {
  color: #0E8140;
  font-size: 15px;
}
.personal-info-container .info-item .property {
  width: 91.2px;
}
.personal-info-container .info-item .value {
  width: 192px;
}
.personal-info-container .k-widget.k-datepicker.k-header {
  width: 97%;
}
.reward-card-container .scan-it-text {
  margin-left: 24px;
}
.health-checkbox-container > div > div {
  height: 29px;
}
.health-checkbox-container > div > div md-checkbox {
  margin-top: 6px;
}
#barcode {
  display: inline-block !important;
  padding-top: 9.6px;
  padding-bottom: 9.6px;
  height: 72px;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.add-charity-button {
  width: 100%;
}
.community-benefits .divider {
  height: 1px;
  background-color: #EAEAEA;
  margin-top: -15px;
  margin-bottom: 15px;
}
.charity-name {
  margin-right: 5px;
}
div.tooltip-container-underneath.community-benefits-total-message {
  left: -6px;
  top: 9px;
}
.community-benefits-edit-button-row {
  margin-top: 5px;
}
.community-benefits-edit-button-row .md-button:first-of-type {
  margin-right: 3px;
}
.home-store-select {
  max-width: 150px;
  margin-left: 40px;
}
.contribution-rate-input {
  width: 47px;
  text-align: right;
  margin-right: 2px;
}
.contribution-rate-total-row-label {
  text-align: right;
  margin-right: 13px;
}
.contribution-rate-total-row-percentage {
  margin-right: 21px;
}
.cardholder-edit {
  height: 15px;
  fill: white;
}
.link-card-segment {
  padding: 12px 20px 8px;
  margin: 0 -24px;
  background-color: rgba(0, 0, 0, 0.2);
}
.link-card-segment form > div,
.link-card-segment > div {
  margin-top: 6px;
}
.cancel-card-segment {
  padding: 12px 20px 8px;
  margin: 0 -24px;
  background-color: rgba(0, 0, 0, 0.2);
}
.cancel-card-segment form > div,
.cancel-card-segment > div {
  margin-top: 6px;
}
.cancel-card-button-segment {
  padding: 12px 20px 8px;
  margin: 0 -24px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
  padding-top: 1px;
}
.cancel-card-button-segment form > div,
.cancel-card-button-segment > div {
  margin-top: 6px;
}
md-icon.set-primary-cardholder-cog {
  cursor: pointer;
  outline: none;
  margin-top: -5px;
  fill: #0E8140;
}
.set-primary-cardholder-container {
  margin-top: 6px;
  margin-right: 10px;
}
.set-primary-cardholder-input-container {
  min-width: 200px;
  margin-right: 10px;
}
.set-primary-cardholder-input-container select {
  width: 100%;
}
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes growUp {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
.carousel-container {
  margin: auto;
}
.carousel-container img {
  display: none;
}
.carousel .slick-center img {
  opacity: 1;
  transform: scale(1.2);
  -webkit-filter: none;
  z-index: 100;
}
.carousel img {
  display: block;
  transition: all 300ms ease;
  opacity: 0.5;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-filter: grayscale(1);
  background-size: auto 100%;
  width: 100%;
  height: 120px;
}
.carousel-container {
  width: 224px;
}
.slick-track {
  padding-top: 20px;
}
.slick-list {
  padding: 0 !important;
}
.slick-prev:before,
.slick-next:before {
  color: black;
  font-family: sans-serif;
}
.carousel-label {
  text-align: center;
  opacity: 0;
  padding-top: 15px;
}
.slick-center > .carousel-label {
  opacity: 1;
}
.slick-loading .slick-list {
  background: none;
}
.heath-star-rating {
  padding-top: 28.8px;
}
.heath-star-rating .star-holder {
  margin-left: 52.8px;
}
.heath-star-rating .star-holder i {
  color: #0E8140;
  height: 14.4px;
  padding-right: 9.6px;
}
.heath-star-rating .value-description {
  margin-left: 14.4px;
}
.recipe-widget-container {
  vertical-align: bottom;
  height: 148.8px;
}
.recipe-widget {
  background: linear-gradient(rgba(100, 100, 100, 0.6), rgba(100, 100, 100, 0.6)), url('/content/images/mockdata/recipe1.jpg');
  background-size: cover;
}
/* Area below the title for value */
.neutral-description::before {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  border: 2px solid #585858;
  content: '';
  display: inline-block;
}
.positive-description::before {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  border: 2px solid #ED2025;
  content: '';
  display: inline-block;
}
.positive-light-description::before {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  border: 2px solid #14b85b;
  content: '';
  display: inline-block;
}
.negative-description::before {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  border: 2px solid #ff3353;
  content: '';
  display: inline-block;
}
.footnote {
  color: #707070;
}
.spinner {
  width: 92px;
}
.spinner .image {
  fill: #14b85b;
  padding-left: 15px;
}
.spinner .up-down-arrow-seperator {
  height: 8px;
}
.spinner .up-down-arrow-container {
  cursor: pointer;
  height: 100%;
  outline: none;
  /* Stop the focus highlight being displayed in Chrome */
}
.spinner .up-down-arrow-container:active md-icon {
  fill: #0E8140;
}
.spinner .up-down-arrow-container md-icon {
  fill: black;
  height: 8px;
  width: 8px;
  padding: 0;
  margin: 0;
}
.spinner .value-container {
  vertical-align: middle;
  width: 1.5em;
  text-align: center;
  user-select: none;
}
.spinner .name-container {
  user-select: none;
}
.chart-horizontal-bar-container .info-container .icon {
  width: 12px;
  margin-right: 10px;
}
.chart-horizontal-bar-container .info-container .legend {
  width: 130px;
}
.chart-horizontal-bar-container .info-container .value {
  width: 48px;
  color: #707070;
}
.chart-horizontal-bar-container .info-container .icon-container {
  width: 38.4px;
  display: inline-table;
}
.chart-horizontal-bar-container .info-container .item {
  padding-bottom: 20px;
}
.chart-horizontal-bar-container .info-container .bar-background {
  background-color: #e2e2e2;
  height: 12px;
}
.chart-horizontal-bar-container .info-container .bar-fill {
  width: 0;
  background-color: #ED2025;
  height: 12px;
  transition: width 2s;
}
.chart-horizontal-bar-container .info-container .ng-enter-prepare .bar-fill {
  width: 0 !important;
}
.chart-horizontal-bar-container .info-container .bar-fill:hover {
  opacity: .8;
}
.chart-horizontal-bar-container .info-container .bar-error {
  background-color: #ff3353;
}
.chart-horizontal-bar-container .info-container .bar-midpoint {
  clear: both;
  height: 200%;
  width: 2px;
  background-color: white;
  position: relative;
  left: 50%;
  top: -150%;
}
.weeks-special-carousel-container {
  margin: auto;
  width: 216px;
  text-align: center;
}
.weeks-special-carousel-container .header {
  font-weight: bold;
  font-size: 16px;
}
.weeks-special-carousel-container .header .header-arrow {
  height: 10px;
  width: 15px;
  color: #0E8140;
  vertical-align: middle;
}
.weeks-special-carousel-container .weeks-special-carousel .slick-center img {
  opacity: 1;
  z-index: 100;
  filter: none;
}
.weeks-special-carousel-container .weeks-special-carousel .blue-badge {
  width: 77px;
  height: 77px;
  background-color: #ED2025;
  color: white;
  border-radius: 50%;
  display: table-cell;
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: 40px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  box-sizing: border-box;
}
.weeks-special-carousel-container .footer {
  padding-top: 24px;
}
.wizard-step-previous {
  transition: 0.8s ease all;
  transform: translateX(-100%);
  opacity: 0;
  z-index: -1;
}
.wizard-step-active {
  transition: 0.8s ease all;
  transform: translateX(0);
  opacity: 1;
  z-index: 1;
}
.wizard-step-next {
  transition: 0s;
  transform: translateX(100%);
  opacity: 0;
  z-index: -1;
}
.wizard-step-hidden {
  transition: 0s;
  transform: translateX(-100%);
  opacity: 0;
  z-index: -1;
}
.wizard-next-arrow {
  height: 9.6px;
  width: 9.6px;
  color: #0E8140;
}
#my-profile-wizard {
  /* Based on the approximate height of the widget. Should match closely (or be calculated).
    If it is too high it will jerk the animation because the ease animation will be at maximum velocity. */
  max-height: 180px;
}
#my-profile-wizard.ng-hide-add.ng-hide-add-active {
  transition: 1s ease;
  transition-property: max-height, padding, margin-bottom;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
}
.my-profile-header-container {
  margin: 0;
  margin-bottom: 15px;
}
.my-profile-step-container {
  margin: 0;
  margin-top: 15px;
}
.my-profile-wizard-step-height {
  height: 48px;
}
.my-profile-action-container {
  width: 77px;
  text-align: right;
  vertical-align: bottom;
}
#date-picker_dateview .k-calendar .k-today .k-link {
  color: white;
  background-color: #0E8140;
}
#date-picker_dateview .k-link.k-nav-fast.k-state-hover {
  background-color: #0E8140;
  color: white;
}
#date-picker_dateview .k-footer a {
  color: #0E8140;
}
/* Only apply to a progress bar not to other materials controls */
md-progress-linear.my-profile-progress-linear .md-container {
  background-color: #e2e2e2 !important;
}
.wizard-input-container {
  padding-left: 30px;
  padding-right: 0;
  /*
        width made 97% instead of 100% as current implementaion of 
        tooltip requires as least some space to appear side by side
        of the input element
    */
}
.wizard-input-container input,
.wizard-input-container select {
  width: 97% !important;
  color: #0E8140;
}
.wizard-input-container .k-widget.k-datepicker.k-header {
  width: 97%;
}
.wizard-input-container .calendar {
  box-sizing: border-box;
  color: #0E8140;
}
.wizard-input-container md-checkbox .md-label {
  margin-left: 21px;
}
.wizard-navigation {
  z-index: 10;
}
/* Only really works with two adjacent buttons at the moment, would need to spend some time potentially setting up dividers so it is styled in a similar fashion to the groupbox.less */
.group-buttons {
  display: flex;
  flex-direction: row;
  /* Hide the radio button and simulate click by clicking the label */
}
.group-buttons .group-button [type=radio] {
  display: none;
}
.group-buttons .group-button {
  margin: 0;
  overflow: hidden;
  height: 38.4px;
  min-width: 90px;
  /*Cannot use button width because it is too wide when used in the my profile wizard for the gender selection.*/
}
.group-buttons label {
  display: block;
  user-select: none;
  cursor: pointer;
  /* Must be padding so that the menu is clickable outside the bounds of the text. */
  padding: 0 12px 0 12px;
  margin: auto;
  line-height: 38.4px;
  width: auto;
  box-sizing: border-box;
  background-color: #F3F3F3;
  /* Font awesome */
}
.group-buttons label i {
  margin-right: 5px;
  color: #14b85b;
}
.group-buttons [type=radio]:checked ~ label {
  color: white;
  background-color: #0E8140;
  /* Font awesome */
}
.group-buttons [type=radio]:checked ~ label i {
  color: white;
}
.group-buttons .start {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right-style: solid;
  border-right-color: white;
  border-right-width: 1px;
}
.group-buttons .end {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
/* https://google-developers.appspot.com/maps/documentation/javascript/places-autocomplete#style_autocomplete */
.pac-container {
  width: inherit !important;
}
.pac-container .pac-icon {
  display: none;
}
.pac-container .pac-item {
  height: 2.5rem;
  line-height: 2.5rem;
  /* Remove the line separators between the search results. */
  border-top: 0;
}
.pac-container .pac-item-selected {
  background-color: #F3F3F3;
}
.pac-container span {
  font-family: 'Bariol';
  font-size: 15px;
}
.gauge-background-circle {
  stroke: #707070;
}
.gauge-primary-arc {
  stroke: #ED2025;
}
.gauge-secondary-arc {
  stroke: #ff3353;
}
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('fonts/slick.eot');
  src: url('fonts/slick.eot?#iefix') format('embedded-opentype'), url('fonts/slick.woff') format('woff'), url('fonts/slick.ttf') format('truetype'), url('fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}
.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: FontAwesome;
  font-size: 8px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  /*Fontawesome circle*/
  content: '\f111';
  text-align: center;
  opacity: .25;
  color: #0E8140;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #0E8140;
}
.usrp-fb-1 {
  display: initial;
  -moz-border-radius: 2px 0 0 2px;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-border-radius: 2px 0 0 2px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.2s ease-in-out;
  border-radius: 2px 0 0 2px;
  cursor: pointer;
  font-size: 14px;
  max-width: 44px;
  overflow: hidden;
  position: fixed;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease-in-out;
  z-index: 1;
}
.usrp-fb-1 i {
  -webkit-transition: all 0.2s linear;
  height: 44px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s linear;
  width: 44px;
}
.usrp-fb-1 .usrp-fb-title {
  -webkit-transition: all 0.2s linear;
  background: #0E8140;
  color: rgba(255, 255, 255, 0);
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  height: 44px;
  line-height: 44px;
  padding: 0 15px 0 65px;
  transition: all 0.2s linear;
  white-space: nowrap;
}
.usrp-fb-1 svg {
  height: 100%;
  width: 100%;
}
.usrp-fb-1 svg .fill {
  fill: #ffffff;
}
.usrp-fb-1:hover {
  max-width: 200px;
}
.usrp-fb-1:hover i {
  background: rgba(0, 0, 0, 0.2);
}
.usrp-fb-1:hover .usrp-fb-title {
  color: #ffffff;
}
.usrp-fb-1.slide-in {
  max-width: 200px;
}
.usrp-fb-1.slide-in i {
  background: rgba(0, 0, 0, 0.2);
}
.usrp-fb-1.slide-in .usrp-fb-title {
  color: #ffffff;
}
md-checkbox.md-default-theme .md-icon,
md-checkbox .md-icon {
  border-color: #0E8140;
  border-width: 1px;
  border-radius: 0;
}
md-checkbox .md-label {
  margin-left: 26px;
}
md-checkbox.md-default-theme.md-checked .md-icon,
md-checkbox.md-checked .md-icon {
  background-color: #0E8140;
}
md-checkbox.md-default-theme.md-checked .md-ink-ripple,
md-checkbox.md-checked .md-ink-ripple {
  color: #0E8140;
}
md-checkbox.md-checked .md-icon:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 12px;
}
md-checkbox .md-icon {
  width: 15px;
  height: 15px;
}
md-checkbox {
  margin-bottom: 0;
}
md-checkbox .md-container {
  width: 15px;
  height: 14px;
  /* Unsure exactly why there is a 1px difference. */
  margin-bottom: 0;
}
md-slider.md-default-theme .md-track,
md-slider .md-track {
  background-color: #707070;
}
md-checkbox[disabled] .md-label {
  color: black !important;
}
md-slider.md-default-theme .md-thumb:after,
md-slider .md-thumb:after {
  border-color: #0E8140;
  background-color: #0E8140;
}
md-slider.md-default-theme .md-track.md-track-fill,
md-slider .md-track.md-track-fill {
  background-color: #0E8140;
}
/* Hide the focus area because it requires too much height */
md-slider.md-default-theme .md-focus-ring,
md-slider .md-focus-ring {
  visibility: hidden;
}
/* Reduce height so that the text underneath is closer */
md-slider {
  margin-left: 0;
  margin-right: 0;
  height: 35px;
}
#shopping-basket-holder {
  font-size: 14px;
  color: #585858;
}
#shopping-basket-holder #shopping-basket-grid {
  height: 458px;
}
#shopping-basket-holder .k-grid-content.k-auto-scrollable {
  height: 420px;
}
#shopping-basket-holder .k-grid-content {
  overflow-y: hidden;
}
#shopping-basket-holder .k-grid-header {
  padding-right: 0 !important;
}
#shopping-basket-holder .k-grid .k-alt {
  background-color: #f5f7f8;
  /* specify the alternate background-color */
}
#shopping-basket-holder .k-grid-header th.k-header {
  text-align: center;
  padding-left: 1.286em;
  padding-right: 1.286em;
}
#shopping-basket-holder .center-align {
  width: 100%;
  text-align: center;
}
#shopping-basket-holder .k-grid tr:hover {
  background: transparent;
}
#shopping-basket-holder .k-grid tr.k-alt:hover {
  background: #f5f7f8;
}
#shopping-basket-holder .k-grid td {
  padding-top: 10px;
  padding-bottom: 10px;
  border-width: 0;
}
#shopping-basket-holder .k-header {
  color: black;
  font-weight: bold;
  border-width: 0;
}
#shopping-basket-holder md-icon {
  height: 10px;
  user-select: none;
}
#shopping-basket-holder md-icon:focus {
  outline: 0;
  box-shadow: none;
}
#shopping-basket-holder .add-to-list-active {
  fill: #0E8140;
  cursor: pointer;
}
#shopping-basket-holder .discount-span {
  background-color: #0E8140;
  color: white;
  border-radius: 10px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 3px;
  padding-bottom: 3px;
}
#shopping-basket-holder .mCSB_dragger_bar {
  background-color: #0E8140 !important;
}
#shopping-basket-holder .mCSB_inside > .mCSB_container {
  margin-right: 0 !important;
}
#shopping-basket-holder .mCSB_scrollTools .mCSB_draggerContainer {
  left: 10px;
}
#shopping-basket-holder .mCSB_draggerRail {
  background-color: #14b85b;
}
#shopping-basket-holder input {
  height: auto;
}
.shopping-list-header {
  height: 29px;
  line-height: 29px;
  background-color: #e2e2e2;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  color: #585858;
  font-size: 14px;
  margin-top: 10px;
}
.expandar-holder {
  text-align: center;
  margin-top: 19.2px;
}
.expandar-holder span {
  cursor: pointer;
  color: #0E8140;
  font-weight: bold;
}
.expandar-holder md-icon {
  height: 10px;
  fill: #e2e2e2;
}
/* All body content should be wrapped like this in order to create a sticky footer. */
.body-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
/* All body content should be wrapped like this in order to create a sticky footer. */
.body-content {
  flex: 1;
}
#shopping-list-holder {
  position: relative;
  /*
    .animate-repeat.ng-move,
    .animate-repeat.ng-enter,
    .animate-repeat.ng-leave {
        transition: all linear .5s;
    }

    .animate-repeat.ng-leave.ng-leave-active,
    .animate-repeat.ng-move,
    .animate-repeat.ng-enter {
        opacity: 0;
    }

    .animate-repeat.ng-leave,
    .animate-repeat.ng-move.ng-move-active,
    .animate-repeat.ng-enter.ng-enter-active {
        opacity: 1;
    }
    */
}
#shopping-list-holder #suggestion-holder {
  position: relative;
}
#shopping-list-holder #dvSuggestions {
  position: absolute;
  background-color: white;
  color: black;
  left: 0;
  right: 0;
  border-radius: 5px;
  z-index: 100;
  border: 1px solid #0E8140;
}
#shopping-list-holder ul,
#shopping-list-holder li {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}
#shopping-list-holder .search-box {
  width: 100%;
  min-width: 264px;
}
#shopping-list-holder .item-holder {
  margin-bottom: 2px;
  cursor: pointer;
  min-height: 2.5rem;
}
#shopping-list-holder .item-holder .item-name {
  width: 145px;
  word-wrap: break-word;
}
#shopping-list-holder .item-holder .add-icon {
  height: 10px;
}
#shopping-list-holder .item-holder:hover {
  background-color: #F3F3F3;
}
#shopping-list-holder .selected-list-container {
  margin-top: 15px;
  margin-bottom: 20px;
  min-height: 250px;
}
#shopping-list-holder .selected-list-container .department-contents {
  max-height: 1000px;
  transition: max-height 0.2s ease-in;
  overflow-y: hidden;
}
#shopping-list-holder .selected-list-container .collapse {
  max-height: 0;
  transition: max-height 0.2s ease-out;
}
#shopping-list-holder .selected-list-container .department-holder {
  margin-bottom: 10px;
}
#shopping-list-holder .selected-list-container .department-holder .department-header {
  background-color: white;
  color: black;
  border-radius: 3px;
}
#shopping-list-holder .selected-list-container .department-holder .selected-item-list-containter {
  margin-left: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 4px;
  max-width: 168px;
  word-break: break-word;
}
#shopping-list-holder .selected-list-container .department-holder .selected-item-list-containter md-checkbox .md-icon {
  border-color: white;
  background-color: white;
}
#shopping-list-holder .selected-list-container .department-holder .selected-item-list-containter md-checkbox.md-checked .md-icon:after {
  border-color: #0E8140;
}
#shopping-list-holder .selected-list-container .department-holder .selected-item-list-containter md-checkbox.md-default-theme.md-checked .md-icon {
  border: white;
}
#shopping-list-holder .selected-list-container .department-holder .delete-icon {
  fill: white;
  cursor: pointer;
}
#shopping-list-holder .selected-list-container .department-holder .badge {
  padding: 4px 7px;
  background-color: #0E8140;
  color: white;
  border-radius: 12px;
  margin-right: 10px;
  font-weight: bold;
  font-size: 12px;
  min-width: 8px;
  text-align: center;
}
#shopping-list-holder .selected-list-container input {
  width: 40px;
  height: 25px;
}
#shopping-list-holder .full-width {
  max-width: 250px !important;
}
#shopping-list-holder md-icon {
  fill: #0E8140;
}
#shopping-list-holder .icon {
  width: 12px;
  margin-right: 10px;
  margin-left: 10px;
}
#shopping-list-holder .first-icon {
  margin-right: 0;
}
#shopping-list-holder .strike-through span {
  text-decoration: line-through;
}
#shopping-list-holder .done-footer {
  margin-top: 10px;
  padding-right: 10px;
  background-color: white;
  font-weight: bold;
  text-align: right;
}
#shopping-list-holder .shopping-list-footer {
  bottom: 0;
  margin-top: 20px;
  line-height: 20px;
  background-color: white;
  color: black;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}
#shopping-list-holder .shopping-list-footer .vertical-middle-align {
  display: flex;
  align-items: center;
}
#shopping-list-holder .mCSB_inside > .mCSB_container {
  margin-right: 5px !important;
}
#shopping-list-holder .mCSB_scrollTools {
  right: -9px !important;
}
#shopping-list-holder .mCSB_scrollTools .mCSB_draggerContainer {
  right: 6px !important;
}
#shopping-list-holder .mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
}
/* Required for gauge control because it must add a custom tooltip */
.tooltip {
  font-size: 14px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.54);
  padding-left: 8px;
  padding-right: 8px;
  height: 32px;
  line-height: 32px;
  color: white;
  white-space: nowrap;
}
/* Shift down a little so that it is in the center of an input field. */
@media only screen and (max-width: 600px) {
  span.tooltip-container {
    display: block;
  }
}
.validation-tooltip {
  position: absolute;
  background-color: red;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  z-index: 10;
  white-space: nowrap;
  transition: .4s linear opacity;
}
.validation-tooltip.ng-enter-prepare {
  display: none;
}
.validation-tooltip.ng-enter {
  opacity: 0;
}
.validation-tooltip.ng-enter.ng-enter-active {
  opacity: 1;
}
.validation-tooltip.ng-leave {
  opacity: 1;
}
.validation-tooltip.ng-leave.ng-leave-active {
  opacity: 0;
}
span.tooltip-container {
  position: relative;
  top: 3px;
  /* Normal validation tooltip with an arrow on the left-hand-side and typically displayed on the right-hand-side of the input field */
}
span.tooltip-container span {
  position: absolute;
  background-color: red;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  z-index: 10;
  white-space: nowrap;
  transition: .4s linear opacity;
}
span.tooltip-container span.ng-enter-prepare {
  display: none;
}
span.tooltip-container span.ng-enter {
  opacity: 0;
}
span.tooltip-container span.ng-enter.ng-enter-active {
  opacity: 1;
}
span.tooltip-container span.ng-leave {
  opacity: 1;
}
span.tooltip-container span.ng-leave.ng-leave-active {
  opacity: 0;
}
span.tooltip-container span::before {
  content: '';
  position: absolute;
  left: -8px;
  top: 6px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-right: 8px solid red;
  border-bottom: 8px solid transparent;
}
div.tooltip-container-underneath {
  position: relative;
  top: 3px;
  /* Normal validation tooltip with an arrow on the left-hand-side and typically displayed on the right-hand-side of the input field */
}
div.tooltip-container-underneath span {
  position: absolute;
  background-color: red;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  z-index: 10;
  white-space: nowrap;
  transition: .4s linear opacity;
}
div.tooltip-container-underneath span.ng-enter-prepare {
  display: none;
}
div.tooltip-container-underneath span.ng-enter {
  opacity: 0;
}
div.tooltip-container-underneath span.ng-enter.ng-enter-active {
  opacity: 1;
}
div.tooltip-container-underneath span.ng-leave {
  opacity: 1;
}
div.tooltip-container-underneath span.ng-leave.ng-leave-active {
  opacity: 0;
}
div.tooltip-container-underneath span::before {
  content: '';
  position: absolute;
  left: 9px;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-bottom: 8px solid red;
  border-right: 8px solid transparent;
}
#login-container .bullet-point-container {
  padding-bottom: 4.8px;
}
#login-container .bullet-point-container md-icon {
  height: 14.4px;
  fill: #ED2025;
}
#cardholder-grid tbody tr {
  cursor: pointer;
}
.md-button[disabled] {
  color: #a5a5a5;
}
.form-field {
  padding-top: 0;
  padding-bottom: 4px;
  /* Small gap so that any footnotes can be added. */
  align-items: center;
  display: flex;
}
.form-field label {
  word-wrap: break-word;
}
.form-field input {
  width: 192px;
}
.form-field .required-indicator {
  color: #0E8140;
}
.form-field-vertical-spacer {
  margin-bottom: 9.6px;
}
.forgot-password .button-holder,
.reset-password .button-holder {
  width: 130px;
}
.forgot-password .email-button-holder,
.reset-password .email-button-holder {
  width: 130px;
}
.forgot-password .input-msg,
.reset-password .input-msg {
  padding-left: 110px;
  top: -10px;
  position: relative;
}
.forgot-password .input-msg span,
.reset-password .input-msg span {
  font-size: 14px;
}
.forgot-password .email-input-msg,
.reset-password .email-input-msg {
  padding-left: 0px;
  top: 0px;
  margin-bottom: 15px;
  position: relative;
}
.forgot-password .email-input-msg span,
.reset-password .email-input-msg span {
  font-size: 14px;
}
.forgot-password .form-field-padding div:first-child,
.reset-password .form-field-padding div:first-child {
  padding-bottom: 0px;
}
md-radio-button.md-default-theme .md-off,
md-radio-button .md-off,
md-radio-button.md-default-theme.md-checked .md-off,
md-radio-button.md-checked .md-off {
  border-color: #0E8140;
}
md-radio-button.md-default-theme .md-on,
md-radio-button .md-on {
  background-color: #0E8140;
}
/* Content is indentied by @widget-padding so that the icon/text aligns with a widget title because usually the banner is display outside of a widget */
.success-banner {
  display: block;
  font-weight: bold;
  background-color: rgba(0, 128, 0, 0.5);
  border-radius: 5px;
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  margin-bottom: 12px;
  height: 38.4px;
  line-height: 38.4px;
}
.success-banner i {
  padding-right: 5px;
}
.error-banner {
  display: block;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 5px;
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  margin-bottom: 12px;
  height: 38.4px;
  line-height: 38.4px;
}
.error-banner i {
  padding-right: 5px;
}
.info-banner {
  display: block;
  font-weight: bold;
  background-color: #a3a0a0;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 24px;
  color: white;
  margin-bottom: 12px;
  height: 38.4px;
  line-height: 38.4px;
}
.info-banner i {
  padding-right: 5px;
}
.overwirtten span {
  font-size: 25px !important;
}
.overwirtten .error-banner {
  height: 120px !important;
  padding-top: 40px;
}
.overwirtten .error-banner i {
  font-size: 30px !important;
}
#terms-and-condition {
  font-family: Bariol !important;
}
#terms-and-condition span {
  font-family: Bariol !important;
  font-size: 15px !important;
}
#terms-and-condition p,
#terms-and-condition .p {
  margin: 0 !important;
  margin-bottom: 24px !important;
}
#terms-and-condition .MsoNormalTable {
  border: none !important;
}
#terms-and-condition .MsoNormalTable td {
  border: none !important;
}
.licensing-agreement {
  padding-left: 15pt;
  padding-right: 15pt;
  padding-bottom: 25pt;
}
.k-state-hover {
  box-shadow: none;
  transition: none;
}
.k-datepicker {
  background-color: transparent;
}
.k-picker-wrap.k-state-default {
  background-color: transparent;
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
}
.k-picker-wrap.k-state-default span.k-select {
  line-height: 30px;
  min-height: 30px;
  border: none;
}
.k-numeric-wrap:before,
.k-picker-wrap:before {
  height: auto;
}
.k-picker-wrap.k-state-focused {
  box-shadow: none !important;
  transition: none;
  background-color: none;
}
.k-calendar-container {
  font-size: 10px !important;
}
.k-calendar-container .k-widget.k-calendar {
  min-width: 240px;
}
.k-calendar-container .k-content {
  font-weight: bold;
}
.k-calendar-container table.k-content thead tr th {
  color: #657076;
  font-weight: bold;
  text-align: center;
}
.k-calendar-container .k-state-hover {
  background-color: rgba(14, 129, 64, 0.1);
}
.k-calendar-container table.k-content td.k-state-selected {
  background-color: transparent;
  box-shadow: none;
}
.k-calendar-container table.k-content td.k-state-selected .k-link {
  color: #14b85b;
  background-color: initial;
  border-radius: initial;
  border-bottom: #14b85b solid 3px;
}
.k-datepicker .k-input {
  background-color: transparent;
  height: 30px !important;
  line-height: 30px !important;
}
.k-picker-wrap .k-input {
  padding: 0;
}
html {
  opacity: 1;
}
.overlay {
  opacity: 0;
}
body,
select,
textarea,
input {
  font-size: 15px !important;
  font-family: 'Bariol' !important;
}
input,
select {
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  color: #0E8140;
  font-weight: bold;
  box-sizing: border-box;
  height: 30px;
}
select:disabled {
  background-color: #707070;
  color: gray;
}
textarea {
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  color: #0E8140;
  font-weight: bold;
  box-sizing: border-box;
}
.inputStyle {
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  outline: none;
  color: #0E8140;
  font-weight: bold;
  box-sizing: border-box;
}
.separate-next-input {
  margin-right: 7px;
}
.small-size-input input {
  width: 100px !important;
  float: left;
  margin-right: 15px;
}
.small-size-input-last input {
  margin-right: 30px !important;
}
.medium-size-input input {
  margin-right: 15px;
  float: left;
  width: 160px !important;
}
select option {
  color: #0E8140;
  font-weight: bold;
}
body {
  background-color: #F3F3F3 !important;
  height: unset !important;
  /* Required otherwise the body's height is limit to the initial height of the window (in Chrome at least). */
}
hr {
  border: none;
  height: 1px;
  width: 100%;
  margin: 0;
  display: block;
  background-color: #F3F3F3;
}
a {
  color: #0E8140;
  text-decoration: none;
}
/* Used for large monetary values */
h1 {
  font-size: 48px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 8px;
}
/* Used for second largest monetary values */
h2 {
  font-size: 29px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 14.4px;
}
h3 {
  font-weight: bold;
  font-size: 19px;
  margin: 0;
  margin-bottom: 9.6px;
}
/* Generally used as the title for the widget's body */
h4 {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  margin-bottom: 19.2px;
}
br {
  display: block;
  margin: 0;
  margin-bottom: 5px;
  content: '';
}
/* .p is used for divs that represents paragraphs */
p,
.p {
  margin: 0;
  margin-bottom: 24px;
}
p br {
  display: block;
  margin-bottom: 9.6px;
}
.section-break {
  margin-top: 33.6px;
  content: '';
}
.clickable {
  cursor: pointer;
  outline: none;
}
.fade-in.ng-hide,
.fade-in.ng-enter {
  opacity: 0;
  transition: 1s ease-in-out opacity;
}
.fade-in.ng-hide-remove,
.fade-in.ng-hide-add {
  transition: 1s ease-in-out opacity;
}
.fade-in.ng-enter.ng-enter-active,
.fade-in.ng-hide-remove-active {
  opacity: 1;
}
.indented-container {
  padding-left: 14.4px;
  width: 100%;
  box-sizing: border-box;
}
md-progress-linear[disabled] {
  visibility: hidden;
}
md-progress-circular[disabled] {
  visibility: hidden;
}
.banner-slick {
  width: 250px;
  height: 250px;
}
.banner-slick div {
  border-radius: 5px;
}
.banner-slick .slick-track {
  padding: 0;
}
.divider {
  height: 1px;
  background-color: #EAEAEA;
}
md-dialog.md-browser-warning-theme {
  max-width: 600px;
}
.md-ripple-container div {
  background-color: #0E8140 !important;
}
md-radio-group.md-default-theme.md-focused:not(:empty) .md-checked .md-container:before,
md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
  background-color: #0E8140 !important;
  opacity: 0.26 !important;
}
/* Colour for underline, buttons and backgrounds for some widgets */
/* Colour used to fill the glyphs such as adult, child, pet */
/* Widget, logo, footer */
