@import (reference) "colours.less";
@import (reference) "page.less";

@wizard-step-transition: 0.8s ease all;

.wizard-step-previous {
    transition: @wizard-step-transition;
    transform: translateX(-100%);
    opacity: 0;
    z-index: -1;
}

.wizard-step-active {
    transition: @wizard-step-transition;
    transform: translateX(0);
    opacity: 1;
    z-index: 1;
}

.wizard-step-next {
    transition: 0s;
    transform: translateX(100%);
    opacity: 0;
    z-index: -1;
}

.wizard-step-hidden {
    transition: 0s;
    transform: translateX(-100%);
    opacity: 0;
    z-index: -1;
}

.wizard-next-arrow {
    height: 2 * @mm-to-pixels;
    width: 2 * @mm-to-pixels;
    color: @accent-color;
}
