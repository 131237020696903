@import (reference) "page.less";
@import (reference) "images.less";

.recipe-widget-container {
    vertical-align: bottom;
    height: 31 * @mm-to-pixels;
}

.recipe-widget {
    .image-darken(url('/content/images/mockdata/recipe1.jpg'));
    background-size: cover;
}
