/* Required for gauge control because it must add a custom tooltip */
@import (reference) "fonts.less";
@import (reference) "button.less";
@import (reference) "style.less";

.tooltip {
    font-size: @font-small-size;
    border-radius: @default-border-radius;
    background-color: rgba(0, 0, 0, 0.54);
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;
    line-height: 32px;
    color: white;
    white-space: nowrap;
}