@import (reference) "colours.less";

md-radio-button.md-default-theme .md-off, md-radio-button .md-off,
md-radio-button.md-default-theme.md-checked .md-off, md-radio-button.md-checked .md-off
{
    border-color: @accent-color;
}

md-radio-button.md-default-theme .md-on, md-radio-button .md-on {
    background-color: @accent-color;
}