@import (reference) "colours.less";
@import (reference) "button.less";

@calendar-default-color: #A7B2BB;
@calendar-day-of-week-color: #657076;
@calendar-other-month-color: #E9EAED;
@calander-bottomBgColor: @primary-color;
@calendar-accent-height: 3px;

/* Kendo calendar */
.k-calendar {
    border-radius: @button-border-radius;
    border: 0;

    .footer {
        .font-small;
        clear: both;
        background-color: @calander-bottomBgColor;
        color: white;
        padding: ceil(2.5 * @mm-to-pixels);
    }
}

.k-calendar-with-border {
    border: 1px solid @tertiary-text-color;
}

.k-calendar table.k-content {
    padding: 0 15px 10px 15px;
}

/* Fixes for Firefox and Edge (ugh) */
.calendar-fix {
    &.k-calendar table.k-content {
        padding: 0;
    }

    &.k-calendar {
        margin: -10px;
    }
}

@-moz-document url-prefix() {
    .calendar-fix();
}

.k-calendar div.k-header {
    margin: 10px 10px 0 10px;
}

/* Arrow icons */
.k-calendar .k-icon.k-i-arrow-w {
    background-position: 0 -48px !important;
}

.k-calendar .k-icon.k-i-arrow-e {
    background-position: 0 -16px !important;
}

/* Hide header */
.k-calendar .k-header {
    background: transparent;
}

/* Header text colour */
.k-calendar .k-header .k-link {
    color: @calendar-default-color;
}

/* Use normal cursor for 'month' header link */
.k-calendar .k-header .k-link.k-nav-fast {
    cursor: default;
}

/* Previous and next arrow links */
.k-calendar .k-header a.k-link.k-nav-next.k-state-hover,
.k-calendar .k-header a.k-link.k-nav-prev.k-state-hover {
    border-radius: initial;
    background-color: @calendar-other-month-color;
}

/* Day of week header */
.k-calendar .k-content thead tr th {
    color: @calendar-day-of-week-color;
    font-weight: bold;
    text-align: center;
}

/* Default day style */
.k-calendar .k-link div {
    color: @calendar-default-color;
    font-weight: bold;
    border-bottom: transparent solid @calendar-accent-height;
}

/* Other month day style */
.k-calendar .k-other-month .k-link div {
    color: @calendar-other-month-color;
}

/* Override today style */
.k-calendar .k-today {
    background-color: initial;
}

/* Today day style */
.k-calendar .k-today .k-link div {
    border-bottom: @accent-color solid @calendar-accent-height;
}

/* Override today focused/active style */
.k-calendar .k-today.k-state-focused, .k-calendar .k-today:active, .k-calendar td.k-state-focused, .k-calendar td.k-state-selected.k-state-focused {
    box-shadow: none;
}

/* Highlighted day style */
.k-calendar .k-link .day-highlight {
    color: @accent-light-color;
    background-color: initial;
    border-radius: initial;
    border-bottom: @accent-light-color solid @calendar-accent-height;
}

.k-calendar .k-other-month.calendar-highlight .k-link div {
    color: @calendar-default-color;
}

/* Fixes issue with animation with rounded corners */
.k-calendar table.k-content {
    background: transparent;
}

@calendar-highlight-border-radius: 50%;
@calendar-highlight-color: fade(@accent-color, 10%);

.k-calendar td.calendar-highlight {
    border-radius: 0;
    background-color: @calendar-highlight-color;
}

.k-calendar td.calendar-highlight-start-date {
    border-top-left-radius: @calendar-highlight-border-radius;
    border-bottom-left-radius: @calendar-highlight-border-radius;
}

.k-calendar td.calendar-highlight-end-date {
    border-top-right-radius: @calendar-highlight-border-radius;
    border-bottom-right-radius: @calendar-highlight-border-radius;
}

/* Override colour when item selected */
.k-calendar td.k-state-selected, .k-calendar td.k-state-selected.k-state-hover {
    background-color: @calendar-highlight-color;
}

/* Hide the date picker icon. It does not match the styling of the application. */
.k-i-calendar {
    visibility: hidden;
}

.shopping-calendar-widget {
    .image-darken(url('/content/images/calendar_background.jpg'));
    background-size: cover;
}
