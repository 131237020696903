@import (reference) "style.less";
@import (reference) "widget.less";
@import (reference) "button.less";

/* Content is indentied by @widget-padding so that the icon/text aligns with a widget title because usually the banner is display outside of a widget */
.banner(@background-color, @padding-left: @widget-padding) {
    display: block;
    font-weight: bold;
    background-color: @background-color;
    border-radius: @default-border-radius;
    padding-left: @padding-left;
    padding-right: @widget-padding;
    color: white;
    margin-bottom: @widget-margin;
    height: @button-height;
    line-height: @button-height;

    // Icon
    i {
        padding-right: 5px;
    }
}

.success-banner {
    .banner(fade(green, 50%));
}

.error-banner {
    .banner(fade(red, 50%));
}

.info-banner {
    .banner(#a3a0a0, @padding-left: 15px);
}

.overwirtten span{
    font-size: 25px !important;
}

.overwirtten .error-banner{
    height:120px !important;
    padding-top: 40px;
}

.overwirtten .error-banner i{
    font-size: 30px !important;
}
