@import (reference) "colours.less";
@import (reference) "page.less";

@checkbox-width-height: 15px;

md-checkbox.md-default-theme .md-icon, md-checkbox .md-icon {
    border-color: @accent-color;
    border-width: 1px;
    border-radius: 0;
}

md-checkbox .md-label {
    margin-left: @checkbox-width-height + ceil(2.2 * @mm-to-pixels);
}

md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
    background-color: @accent-color;
}

md-checkbox.md-default-theme.md-checked .md-ink-ripple, md-checkbox.md-checked .md-ink-ripple  {
    color: @accent-color;
}

md-checkbox.md-checked .md-icon:after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 12px;
}

md-checkbox .md-icon {
    width: @checkbox-width-height;
    height: @checkbox-width-height;
}

md-checkbox {
    margin-bottom: 0;
}

md-checkbox .md-container {
    width: @checkbox-width-height;
    height: @checkbox-width-height - 1;     /* Unsure exactly why there is a 1px difference. */
    margin-bottom: 0;
}

md-slider.md-default-theme .md-track, md-slider .md-track {
    background-color: @tertiary-text-color;
}

 md-checkbox[disabled] .md-label{
    color: black !important;
 }
