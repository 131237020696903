@import (reference) "style.less";

.heath-star-rating {
    padding-top: 6 * @mm-to-pixels;
    .star-holder {
        margin-left: 11 * @mm-to-pixels;

        i{
            color: @accent-color;
            height: 3 * @mm-to-pixels;
            padding-right: 2 * @mm-to-pixels;
        }
    }

    .value-description {
        margin-left: 3 * @mm-to-pixels;
    }
}
