@import (reference) "style.less";

.chart-horizontal-bar-container {

    .info-container {

        .icon {
            width: ceil(2.4 * @mm-to-pixels);
            margin-right: ceil(2 * @mm-to-pixels);
        }

        .legend {
            width: 130px;
        }

        .value {
            width: 10 * @mm-to-pixels;
            color: @tertiary-text-color;
        }
        // Delta container
        .icon-container {
            width: 8 * @mm-to-pixels;
            display: inline-table;
        }

        .item {
            padding-bottom: ceil(4 * @mm-to-pixels);
        }

        .bar-background {
            background-color: @common-theme-color;
            height: ceil(2.5 * @mm-to-pixels);
        }

        .bar-fill {
            width: 0; // Set initial width so the it can be animated
            background-color: @primary-color;
            height: ceil(2.5 * @mm-to-pixels);
            transition: width 2s;
        }

        .ng-enter-prepare .bar-fill {
            width: 0 !important; // Set width prior to animation to override the ng-style width
        }

        .bar-fill:hover {
            opacity: .8;
        }

        .bar-error {
            background-color: @warning-color;
        }

        .bar-midpoint {
            clear: both;
            height: 200%;
            width: 2px;
            background-color: white;
            position: relative;
            left: 50%;
            top: -150%;
        }
    }
}
