@import (reference) "page.less";
@import (reference) "colours.less";
@import (reference) "calendar.less";


#my-profile-wizard {
    /* Based on the approximate height of the widget. Should match closely (or be calculated).
    If it is too high it will jerk the animation because the ease animation will be at maximum velocity. */
    max-height: 180px;
}

#my-profile-wizard.ng-hide-add.ng-hide-add-active {
    transition: 1s ease;
    transition-property: max-height, padding, margin-bottom;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    max-height: 0;
    overflow: hidden;
}

.my-profile-header-container {
    margin: 0;
    margin-bottom: ceil(3 * @mm-to-pixels);
}

.my-profile-step-container {
    margin: 0;
    margin-top: ceil(3 * @mm-to-pixels);
}

.my-profile-wizard-step-height {
    height: ceil(10 * @mm-to-pixels);
}

.my-profile-action-container {
    width: ceil(16 * @mm-to-pixels);
    text-align: right;
    vertical-align: bottom;
}


#date-picker_dateview {
    .k-calendar .k-today .k-link {
        color: white;
        background-color: @accent-color;
    }

    .k-link.k-nav-fast.k-state-hover {
        background-color: @accent-color;
        color: white;
    }

    .k-footer a {
        color: @accent-color;
    }
}

/* Only apply to a progress bar not to other materials controls */
md-progress-linear.my-profile-progress-linear .md-container {
    background-color: @common-theme-color !important;
}

.wizard-input-container {
    padding-left: 30px;
    padding-right: 0;
    /*
        width made 97% instead of 100% as current implementaion of 
        tooltip requires as least some space to appear side by side
        of the input element
    */
    input, select {
        width: 97% !important;
        color: @accent-color;
    }
    //kendo datepicker, make tool-tip appear side by side
    .k-widget.k-datepicker.k-header {
        width: 97%;
    }

    .calendar {
        box-sizing: border-box;
        color: @accent-color;
    }

    md-checkbox .md-label {
        margin-left: 21px;
    }
}

.wizard-navigation {
    z-index: 10;
}
