@import (reference) "style.less";
@import (reference) "colours.less";

.forgot-password, .reset-password {
    .button-holder {
        width: ceil(27 * @mm-to-pixels);
    }

    .email-button-holder {
        width: ceil(27 * @mm-to-pixels);
        //margin-left:50px;
    }

    .input-msg {
        padding-left: 110px;
        top: -10px;
        position: relative;

        span {
            font-size: 14px;
        }
    }

    .email-input-msg {
        padding-left: 0px;
        top: 0px;
        margin-bottom:15px;
        position: relative;

        span {
            font-size: 14px;
        }
    }

    .form-field-padding div:first-child {
        padding-bottom: 0px;
    }
}
