/* Only really works with two adjacent buttons at the moment, would need to spend some time potentially setting up dividers so it is styled in a similar fashion to the groupbox.less */

@import (reference) "page.less";
@import (reference) "style.less";
@import (reference) "button.less";
@import (reference) "colours.less";

.group-buttons {

    display: flex;
    flex-direction: row;

    /* Hide the radio button and simulate click by clicking the label */
    .group-button [type=radio] {
        display: none;
    }
    
    .group-button {
        margin: 0;
        overflow: hidden;
        height: @button-height;
        min-width: 90px;    /*Cannot use button width because it is too wide when used in the my profile wizard for the gender selection.*/
    }
    
    label {
        display: block;
        user-select: none;

        cursor: pointer;
        margin: auto;

        /* Must be padding so that the menu is clickable outside the bounds of the text. */
        padding: 0 @button-padding 0 @button-padding;
        
        margin: auto;
        line-height: @button-height;

        width: auto;
        box-sizing: border-box;
        background-color: @body-background-color;

        /* Font awesome */ 
        i {
            margin-right: ceil(1 * @mm-to-pixels);
            color: @accent-light-color;
        }
    }

    [type=radio]:checked ~ label {
        color: white;
        background-color: @accent-color;
        
        /* Font awesome */ 
        i {
            color: white;
        }
    }

    .start {
        border-top-left-radius:  @button-border-radius;
        border-bottom-left-radius:  @button-border-radius;

        border-right-style: solid;
        border-right-color: white;
        border-right-width: 1px;
    }
    
    .end {
        border-top-right-radius:  @button-border-radius;
        border-bottom-right-radius:  @button-border-radius;
    }
}