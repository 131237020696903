@import (reference) "page.less";
@import (reference) "colours.less";
@import (reference) "titles.less";
@import (reference) "fonts.less";
@import (reference) "animations.less";

@menu-highlight-color: @accent-color;
@menu-text-color: black;
@menu-background-color: white;

@menu-logo-url: null;
@menu-horizontal-margin: ceil(6 * @mm-to-pixels);

.mereo-title {
    font-size: 4.5 * @mm-to-pixels;
    font-weight: bold;
    color: @accent-color;
    position: relative;
    top: -2 * @mm-to-pixels;
    text-align: center;
    .mereo-title;
}

.mereo-title() when (isstring(@menu-logo-url)) {
    display: none;
}

.mereo-merchant-logo {
    position: relative;
    height: 4.5 * @mm-to-pixels;
    top: -3 * @mm-to-pixels;
    min-height: 50px;
    .mereo-merchant-logo;
    /* Image container */
    div {
        background-repeat: no-repeat;
        background-size: contain;
        height: 10 * @mm-to-pixels;
        width: 200px;
        border-style: none;
        background-image: url(@menu-logo-url);
        background-position:center;
        margin: 0 auto;
    }
}

.mereo-merchant-logo-virtual {
    position: relative;
    height: 4.5 * @mm-to-pixels;
    top: -3 * @mm-to-pixels;
    min-height: 55px;
    background-color:@menu-background-color;
    margin: -15px -25px 20px -25px;
    .mereo-merchant-logo-virtual;
    /* Image container */
    div {
        background-repeat: no-repeat;
        background-size: contain;
        height: 10 * @mm-to-pixels;
        width: 200px;
        border-style: none;
        background-image: url(@menu-logo-url);
        background-position:center bottom;
        margin: 0 auto;
    }
}

.mereo-merchant-logo-virtual() when not (isstring(@menu-logo-url)) {
    display: none;
}

.mereo-merchant-logo() when not (isstring(@menu-logo-url)) {
    display: none;
}

.menu {
    background-color: @menu-background-color;

    label {
        color: @menu-text-color;
    }
    a {
         color: @menu-text-color !important;
    }
}

.menu-container {
    padding-top: ceil(6 * @mm-to-pixels);
   /* padding-bottom: ceil(3 * @mm-to-pixels); */
}

.menu {
    .menu-item {

        label {
            cursor: pointer;
            display: inline-block;
            /* Must be padding so that the menu is clickable outside the bounds of the text. */
            padding-left: @menu-horizontal-margin;
            padding-right: @menu-horizontal-margin;
            font-size: ceil(2.6 * @mm-to-pixels);
            font-weight: bold;

            &:hover {
                color: @menu-text-color;
            }

            &.selected {
                color: @menu-text-color;
            }
        }

        .underline {
            margin-left: @menu-horizontal-margin;
            .accented(@underline-color: #5c5c5c, @margin-underneath: 1);
        }

        a {
            color: @menu-text-color;
            font-size: ceil(2.6 * @mm-to-pixels) !important;
            font-weight: bold !important;
            cursor: pointer;
            &:hover {
                color: @menu-text-color !important;
            }
        }
    }

    .divider {
        width: 1px;
        height: @font-normal-size; /* Not ideal. Using 1rem did not really work either. */
        background-color: @tertiary-text-color;
    }

    md-icon {
        height: 15px;
        margin: 0;
        margin-top: 1px !important;
        fill: @menu-highlight-color !important;
    }
}

.navbar{
    float:right;
}

@media only screen and (max-width: @layout-breakpoint-xs){
    .navbar{
        float:left;
    }
    .menu{
        padding-bottom:15px;
    }
}

