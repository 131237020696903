@import (reference) "colours.less";
@import (reference) "page.less";

/* Area below the title for value */
.description-section(@dot-point-color) {

    &::before {
        height: ceil(.8 * @mm-to-pixels);
        width: ceil(.8 * @mm-to-pixels);
        border-radius: 50%;
        border: ceil(.3 * @mm-to-pixels) solid @dot-point-color;
        content: '';
        display: inline-block;
    }
}

.neutral-description {
    .description-section(@dot-point-color: @secondary-text-color);
}

.positive-description {
    .description-section(@dot-point-color: @primary-color);
}

.positive-light-description {
    .description-section(@dot-point-color: @accent-light-color);
}

.negative-description {
    .description-section(@dot-point-color: @warning-color);
}

.footnote {
    color: @tertiary-text-color;
}