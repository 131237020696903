@import (reference) "colours.less";

@color0: #ffffff;

@width0: 200px;
@width2: 44px;

@height1: 44px;

.usrp-fb-1 {
    display: initial;
    -moz-border-radius: 2px 0 0 2px;
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -webkit-border-radius: 2px 0 0 2px;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-transition: all 0.2s ease-in-out;
    border-radius: 2px 0 0 2px;
    cursor: pointer;
    font-size: 14px;
    max-width: @width2;
    overflow: hidden;
    position: fixed;
    right: 0;
    bottom: 0;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease-in-out;
    z-index: 1;

    i {
        -webkit-transition: all 0.2s linear;
        height: @height1;
        left: 0;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;
        width: @width2;
    }

    .usrp-fb-title {
        -webkit-transition: all 0.2s linear;
        background: @accent-color;
        color: rgba(255, 255, 255, 0);
        display: block;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        height: @height1;
        line-height: @height1;
        padding: 0 15px 0 65px;
        transition: all 0.2s linear;
        white-space: nowrap;
    }

    svg {
        height: 100%;
        width: 100%;

        .fill {
            fill: @color0;
        }
    }

    &:hover {
        max-width: @width0;

        i {
            background: rgba(0, 0, 0, 0.2);
        }

        .usrp-fb-title {
            color: @color0;
        }
    }
}

.usrp-fb-1.slide-in {
    max-width: @width0;

    i {
        background: rgba(0, 0, 0, 0.2);
    }

    .usrp-fb-title {
        color: @color0;
    }
}
